import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { TierInfo } from '@decub8/ui';
import { log } from '@logtail/next';

import { CONTENT } from '@src/config';
import { setWalletVerified } from '@src/features/auth';
import { useAppSelector } from '@src/hooks/index';
import { GlobalContext, useGlobalContext } from '@src/hooks/useGlobalContext';
import { ContractType } from '@src/ts/constants';

const useTierWithoutBoost = () => {
    const { _userTier, _tiers } = useContext(GlobalContext);

    if (!_userTier || !_tiers) return null;
    if (!_userTier.boost_percentage) return _userTier;

    for (let i: number; i < _tiers.length; i++) {
        const is_last_tier = i === _tiers.length - 1;

        if (
            _userTier.actual_amount >= _tiers[i].min_limit &&
            (is_last_tier
                ? true
                : _userTier.actual_amount < _tiers[i + 1].min_limit)
        ) {
            return _tiers[i];
        }
    }

    return null;
};

export const useTier = (): TierInfo | undefined => {
    const {
        _userTier,
        _tiers,
        _setTierDrawerOpen,
        _hasFetchedUserTier,
        contract_manager,
        user_status: { has_verified_wallet, has_verified_identity },
    } = useGlobalContext();
    const tier_img = _userTier?.image_url || CONTENT.tiers.NoTierImage;
    const { user } = useAppSelector((state) => state.auth);

    const tier_without_boost = useTierWithoutBoost();
    const is_max_without_boost =
        _tiers?.length && tier_without_boost?.id === _tiers?.length - 1;
    const dispatch = useDispatch();

    const fetchWalletVerified = useCallback(() => {
        if (!user?.wallet_address || !contract_manager) return;

        contract_manager
            .getContract(ContractType.WalletStore)
            .contract.isVerified(user.wallet_address)
            .then((v: boolean) => {
                dispatch(setWalletVerified(v));
            })
            .catch((err: unknown) => {
                log.error(
                    `Error checking if user's wallet is verified: ${user.wallet_address}`,
                    err,
                );
            });
    }, [user, contract_manager]);

    useEffect(() => {
        fetchWalletVerified();
    }, [fetchWalletVerified]);

    return useMemo(
        () =>
            _userTier && _hasFetchedUserTier
                ? {
                      has_boost: !!_userTier.boost_percentage,
                      tier_string: `${_userTier?.name}  ${
                          _userTier?.multiplier > 1
                              ? `${_userTier?.multiplier}x`
                              : ''
                      } `,
                      tier_img_url: tier_img,
                      tier_boost: _userTier.boost_percentage,
                      tier_without_boost: `${
                          tier_without_boost
                              ? _tiers[tier_without_boost?.id]?.name ||
                                'Loading...'
                              : 'None'
                      }`,
                      handleClick:
                          has_verified_identity && has_verified_wallet
                              ? () => _setTierDrawerOpen(true)
                              : undefined,
                  }
                : undefined,

        [_userTier, is_max_without_boost, _tiers, tier_img, tier_without_boost],
    );
};
