import { api_client } from '@src/bootstrap/index';
import { CONTENT } from '@src/config';
import { CONTENT_API } from '@src/services/content';
import { PROJECT } from '@src/services/project';
import { EventType } from '@src/ts/constants';
import {
    ICompletedEventsResponse,
    IContentSection,
    NotificationData,
} from '@src/ts/interfaces';

export const getNotificationData = async (): Promise<NotificationData> => {
    const { getNotification } = await api_client.query<{
        getNotification: NotificationData;
    }>({
        query: PROJECT.GET_NOTIFICATION_DATA,
        fetchPolicy: 'network-only',
    });

    return getNotification;
};

export const getCompletedEventsData = async (
    offset: number,
    amount: number,
    type: EventType,
): Promise<ICompletedEventsResponse> => {
    const { retrieveCompletedEvents: completed_events } =
        await api_client.query<{
            retrieveCompletedEvents: ICompletedEventsResponse;
        }>({
            query: CONTENT_API.RETRIEVE_COMPLETED_EVENTS,
            fetchPolicy: 'network-only',
            variables: { offset, amount, event_type: type },
        });

    return completed_events;
};

export const getPlatformImages = async () => {
    const images = await api_client.query({
        query: PROJECT.GET_PLATFORM_IMAGES,
    });
    return images['retrieveImages'];
};

export const getIDOSectionProps = (section: IContentSection) => {
    return {
        title: section?.title,
        subtitle: section?.subtitle,
        handleClick: () => {
            window.open(CONTENT.apply_for_ido_link, '_blank', 'norefferer');
        },
        desktop_image: section?.images?.find(
            (image) => image.name === 'desktop',
        )?.url,
        mobile_image: section?.images?.find((image) => image.name === 'mobile')
            ?.url,
    };
};
