import React from 'react';
import { MyAllocationCard } from '@decub8/ui';

import { useAppSelector } from '@src/hooks/index';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { useUserAllocationMultiplier } from '@src/hooks/useUserAllocationMultiplier';
import { User } from '@src/ts/interfaces';

const getAllocationCardMessage = (
    has_verified_identity: boolean,
    has_pending_verification_status: boolean,
    has_verified_wallet: boolean,
    is_wallet_pending: boolean,
    user: User,
) => {
    if (!user) {
        return 'Log in to see your allocation weight.';
    } else if (has_pending_verification_status) {
        return 'Please wait for ID verification to check allocation.';
    } else if (!has_verified_identity && !has_pending_verification_status) {
        return 'Verify your ID to check allocation.';
    } else if (is_wallet_pending) {
        return 'Please wait for wallet verification to check allocation.';
    } else if (!has_verified_wallet && !is_wallet_pending) {
        return 'Verify your wallet to check allocation.';
    } else return undefined;
};

export const AllocationCardContainer: React.FC<{ className?: string }> = ({
    className,
}) => {
    const {
        _tiers,
        _userTier,
        user_status: {
            has_verified_identity,
            has_pending_verification_status,
            has_verified_wallet,
            is_wallet_pending,
        },
        setShowAllocationModal,
    } = useGlobalContext();

    const { minimum_dcb_power, user_allocation_multiplier } =
        useUserAllocationMultiplier(_tiers, _userTier);

    const user = useAppSelector((state) => state?.auth.user);

    const calculateSliderPercentage = (currentValue, startValue, endValue) => {
        return ((currentValue - startValue) / (endValue - startValue)) * 100;
    };

    const message = getAllocationCardMessage(
        has_verified_identity,
        has_pending_verification_status,
        has_verified_wallet,
        is_wallet_pending,
        user,
    );

    const next_tier_idx = (_userTier?.id || 0) + 1;

    const upper_tier_idx =
        _tiers?.length - 1 > next_tier_idx ? next_tier_idx : _tiers?.length - 1;

    const upper_tier = (_tiers || [])[upper_tier_idx];

    const user_multipler = _userTier?.multiplier || 1;

    const is_max_tier = _userTier?.id === _tiers?.length - 1;
    const next_multiplier = is_max_tier ? user_multipler + 1 : 1;

    const upper_tier_allocation_multiplier = parseFloat(
        (
            ((upper_tier?.min_limit || 0) * (next_multiplier || 1)) /
            (minimum_dcb_power || 1)
        ).toFixed(1),
    );

    const lower_tier_allocation_multiplier = parseFloat(
        (
            ((_userTier?.min_limit || 0) * (user_multipler || 1)) /
            (minimum_dcb_power || 1)
        ).toFixed(1),
    );

    return (
        <div className={className}>
            <MyAllocationCard
                handleClick={() => setShowAllocationModal(true)}
                current_allocation={user_allocation_multiplier}
                message={message}
                current_tier={`${_userTier?.name}${
                    is_max_tier ? ` ${user_multipler}x` : ''
                }`}
                next_tier={`${_tiers[upper_tier_idx]?.name}${
                    is_max_tier ? ` ${next_multiplier}x` : ''
                }`}
                upper_allocation_multiplier={upper_tier_allocation_multiplier}
                lower_allocation_multiplier={
                    lower_tier_allocation_multiplier === 0
                        ? 1
                        : lower_tier_allocation_multiplier
                }
                progress_percent={calculateSliderPercentage(
                    user_allocation_multiplier,
                    lower_tier_allocation_multiplier === 0
                        ? 1
                        : lower_tier_allocation_multiplier,
                    upper_tier_allocation_multiplier,
                )}
            />
        </div>
    );
};
