import { useState } from 'react';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';

import { api_client } from '@src/bootstrap';
import { DEFAULT_CHAIN_ID } from '@src/config';
import {
    setVerificationModalDetails,
    setVerificationModalOpen,
} from '@src/features/layout';
import { useAppDispatch } from '@src/hooks';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { USER } from '@src/services';
import { newAnalyticsEvent } from '@src/utils/user';

export const useVerifyWallet = ({
    is_event_widget_flow,
}: {
    is_event_widget_flow?: boolean;
}) => {
    const { contract_manager } = useGlobalContext();
    const { account, connect } = useWeb3Onboard();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [modal_open, setModalOpen] = useState(false);
    const router = useRouter();

    // 1. kyc pendijg -> wallet will not be verified so  pending
    // 2. kyc verified -> wallet will be verified soon so wait?

    const handleWhitelist = async () => {
        setLoading(true);

        let success = false;
        let pending = false;
        try {
            const { verifyWalletAddress: tx_hash } = await api_client.mutate<{
                verifyWalletAddress: string;
            }>({
                mutation: USER.VERIFY_WALLET_ADDRESS,
                variables: { address: account },
            });

            if (!is_event_widget_flow) {
                router.push('/account');
                dispatch(
                    setVerificationModalDetails({
                        body: 'Thank you for verifying your wallet. Verification will be finalized as soon as your account is fully verified.',
                        title: 'Wallet verified',
                    }),
                );
                dispatch(setVerificationModalOpen(true));
            }

            if (tx_hash) {
                await contract_manager
                    .getProvider(DEFAULT_CHAIN_ID)
                    .waitForTransaction(tx_hash);
            } else {
                pending = true;
            }

            success = true;
            setModalOpen(false);

            newAnalyticsEvent('verify_wallet');
        } catch (err) {
            success = false;
            toast.error(err.message);
        } finally {
            setLoading(false);
        }

        return {
            pending,
            success,
        };
    };

    return {
        account,
        connect,
        loading,
        modal_open,
        setModalOpen,
        handleWhitelist,
    };
};
