import React, { FC, useEffect, useState } from 'react';
import { Alert, Card, DataLines, Media, Typography } from '@decub8/ui';
import { commify } from '@ethersproject/units';
import dayjs from 'dayjs';

import { CONTENT } from '@src/config';
import { useAppSelector } from '@src/hooks/index';
import { useEventDates } from '@src/hooks/useEventTimes';
import { EventModalState, useGlobalContext } from '@src/hooks/useGlobalContext';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { EventType } from '@src/ts/constants';
import { ProjectEvent, User } from '@src/ts/interfaces';
import { getMinLimitForTiers } from '@src/utils/getters';

import { useProjectContext } from '../../context';
import { ModalType } from '../EventModals';

import { WhitelistSection } from './WhitelistSection';

export const getModalState = (
    setModalState: (state: EventModalState) => void,
    has_correct_tier: boolean,
    has_verified_identity: boolean,
    has_verified_wallet: boolean,
    user: User,
    _setTierDrawerOpen: (open: boolean) => void,
    has_pending_verification_status: boolean,
    account: string,
    wallet_verification_submitted: boolean,
    has_failed_verification_status: boolean,
) => {
    if (!user) {
        setModalState({
            show: true,
            modal_type: ModalType.Login,
        });
    } else if (!wallet_verification_submitted && !account) {
        setModalState({
            show: true,
            modal_type: ModalType.VerifyConnectWallet,
        });
    } else if (!wallet_verification_submitted && account) {
        setModalState({
            show: true,
            modal_type: ModalType.VerifyWallet,
        });
    } else if (!has_verified_identity && has_failed_verification_status) {
        setModalState({
            modal_type: ModalType.FailedVerification,
            show: true,
        });
    } else if (!has_verified_identity && !has_pending_verification_status) {
        setModalState({
            show: true,
            modal_type: ModalType.KYC,
        });
    } else if (!has_verified_wallet) {
        setModalState({
            show: true,
            modal_type: ModalType.VerificationPending,
        });
    } else if (!has_correct_tier) {
        setModalState({
            show: true,
            modal_type: ModalType.UpgradeTier,
        });
    }
};
export const EventRegister: FC<{
    event: ProjectEvent;
    answer?: string[];
}> = ({ event, answer = [] }) => {
    const {
        registered,
        registerForWhitelist,
        whitelist_loading,
        num_whitelisted,
    } = useProjectContext();
    const { user } = useAppSelector((state) => state.auth);
    const { event_details: { min_tier = 0 } = {} } = event || {};
    const { wallet_verified } = useAppSelector((state) => state?.auth) || {};
    const [minimum_dcb_power, setMinimumDcbPower] = useState<number | null>(
        null,
    );

    const {
        _userTier,
        _setTierDrawerOpen,
        setEventModalState,
        _tiers,
        user_status: {
            has_verified_identity,
            has_pending_verification_status,
            has_failed_verification_status,
            has_verified_wallet,
        },
        setShowAllocationModal,
    } = useGlobalContext();

    const { account } = useWeb3Onboard();

    const is_crowdfunding = event.type === EventType.Crowdfunding;

    const start = dayjs(Number(event?.start_date) * 1000);

    const { has_reached_cutoff, is_now_cutoff_period, has_started } =
        useEventDates(start, event?.event_details?.durations, event?.type);

    const has_correct_tier = user ? _userTier?.id >= min_tier : true;

    const has_submitted_wallet = !!user?.wallet_address;

    const can_whitelist =
        user &&
        !registered &&
        has_correct_tier &&
        has_verified_identity &&
        wallet_verified &&
        !has_pending_verification_status;

    useEffect(() => {
        if (minimum_dcb_power) return;

        getMinLimitForTiers(_tiers)
            .then((min) => {
                setMinimumDcbPower(min);
            })
            .catch((err) =>
                console.log('Error fetching minimum DCB power for tiers', err),
            );
    }, [minimum_dcb_power, _tiers]);

    // TODO: maybe this is a bad way to stop the whitelist button from showing
    if (!event?.contract?.abi || !event?.contract?.address) {
        return <div></div>;
    }

    const handleClick = can_whitelist
        ? () => {
              registerForWhitelist(event.id, answer);
          }
        : () =>
              getModalState(
                  setEventModalState,
                  has_correct_tier,
                  has_verified_identity,
                  wallet_verified,
                  user,
                  _setTierDrawerOpen,
                  has_pending_verification_status,
                  account,
                  has_submitted_wallet,
                  has_failed_verification_status,
              );

    const user_allocation_multiplier =
        _userTier && minimum_dcb_power
            ? (_userTier.power / minimum_dcb_power).toFixed(1)
            : 1;

    // when have not registyered, have not wallet verified, have not kyc

    // display other message when has everythign except for coprrect tier
    const verfied_but_wrong_tier =
        user && wallet_verified && has_verified_identity && !has_correct_tier;

    const display_whitelist_section =
        !registered && !has_reached_cutoff && !verfied_but_wrong_tier;

    return (
        <div>
            {is_crowdfunding && has_reached_cutoff && (
                <DataLines
                    className="mb-5"
                    label="Whitelisted participants"
                    values={[{ value: commify(num_whitelisted) }]}
                />
            )}

            {registered && !has_started && (
                <Alert variant="note" has_icon>
                    <Typography variant="secondary" size="sm">
                        {`You are whitelisted with ${user_allocation_multiplier}x
                    allocation weight. ${
                        !has_started && is_crowdfunding
                            ? 'Your max allocation will be revealed shortly.'
                            : ''
                    } `}
                        {!is_now_cutoff_period && (
                            <span
                                onClick={() => setShowAllocationModal(true)}
                                className="text-accent underline cursor-pointer"
                            >
                                Increase your allocation weight.
                            </span>
                        )}
                    </Typography>
                </Alert>
            )}

            {!registered && is_crowdfunding && has_reached_cutoff && (
                <Alert variant="warning">
                    <span className="text-warning">
                        You are not whitelisted for this event.
                    </span>
                </Alert>
            )}

            {display_whitelist_section && (
                <WhitelistSection
                    button_disabled={whitelist_loading || registered}
                    button_loading={whitelist_loading}
                    className="mt-5"
                    terms_url={CONTENT.termsAndConditions}
                    handleClick={handleClick}
                    saft_url={event.saft_url || CONTENT.saft}
                />
            )}
            {verfied_but_wrong_tier &&
                !has_reached_cutoff &&
                has_verified_identity &&
                has_verified_wallet && (
                    <div>
                        <Typography size="sm" variant="secondary">
                            To whitelist for this event, you must be at least a{' '}
                            <span className="text-primary font-bold">
                                {_tiers[min_tier]?.name}
                            </span>{' '}
                            tier.
                        </Typography>
                        <Card
                            surface="custom"
                            onClick={() => setShowAllocationModal(true)}
                            className="flex justify-between items-center px-5 py-3 mt-5 bg-surface-level-two hover:bg-surface-level-three cursor-pointer"
                        >
                            <Typography variant="secondary">
                                Upgrade your investor tier
                            </Typography>
                            <Media
                                variant="arrow-right"
                                className="text-accent  h-[17px]"
                            />
                        </Card>
                    </div>
                )}
        </div>
    );
};
