import { Contract } from '@ethersproject/contracts';
import { log } from '@logtail/next';

import { api_client } from '@src/bootstrap/index';
import { IContractManager } from '@src/contracts/manager';
import { PROJECT } from '@src/services/project';
import { Project, ProjectToken } from '@src/ts/interfaces';
import {
    Crowdfunding,
    ProjectContract,
    User,
    UserWhitelistSummary,
} from '@src/ts/interfaces';

export const getCrowdfundingDetails = async (
    event_id: number,
    crowdfunding: Contract,
    account?: string,
): Promise<Crowdfunding> => {
    // TODO: should I get the length here or below like I did
    const [num_invested] = await Promise.all([crowdfunding.getParticipants()]);

    const [info] = await Promise.all([crowdfunding.getInfo()]);
    const { getWhitelistSummary: whitelist_summary } = (await api_client.query({
        query: PROJECT.GET_WHITELIST_SUMMARY,
        variables: {
            event_id,
        },
        fetchPolicy: 'network-only',
    })) as { getWhitelistSummary: UserWhitelistSummary };

    const { user_allocation, num_whitelisted } = whitelist_summary;

    let initial_user_loaded;
    let user_investment = '0';
    let vesting_address = '';

    if (account) {
        initial_user_loaded = true;
        [user_investment, vesting_address] = await Promise.all([
            crowdfunding.userInvestment(account),
            crowdfunding.vesting(),
        ]);
    } else {
        initial_user_loaded = false;
    }

    return {
        event_id,
        start_date: new Date(info[1].mul(1000).toNumber()).toISOString(),
        end_date: new Date(info[2].mul(1000).toNumber()).toISOString(),
        hardcap: info[0].toString(),
        total_raised: info[3].toString(),
        num_whitelisted,
        num_invested: num_invested.length,
        user_investment: user_investment.toString(),
        initial_user_loaded,
        refund_fee: user_allocation?.refund_fee,
        vesting_address,
    };
};

export const getLegacyCrowdfundingDetails = async (
    event_id: number,
    crowdfunding: Contract,
    account?: string,
    user_investment = '0',
): Promise<Crowdfunding> => {
    const info = await crowdfunding.getInfo();

    // TODO: should I get the length here or below like I did
    const [num_invested] = await Promise.all([crowdfunding.getParticipants()]);

    const has_registered_users_func = !!crowdfunding.getRegisteredUsers;

    let num_whitelisted;
    let hardcap;
    let start_date;
    let end_date;
    let total_raised;

    if (has_registered_users_func) {
        const registered_users = await crowdfunding.getRegisteredUsers();
        num_whitelisted = registered_users.length;

        hardcap = info[0].toString();
        start_date = new Date(info[2].mul(1000).toNumber()).toISOString();
        end_date = new Date(info[3].mul(1000).toNumber()).toISOString();
        total_raised = info[4].toString();
    } else {
        num_whitelisted = info[6].toString();

        hardcap = info[1].toString();
        start_date = new Date(info[3].mul(1000).toNumber()).toISOString();
        end_date = new Date(info[4].mul(1000).toNumber()).toISOString();
        total_raised = info[5].toString();
    }

    let vesting_address = '';

    try {
        vesting_address = await crowdfunding.vesting();
    } catch (err) {
        log.error('error fetching vesting address', err);
    }

    let investment_details = {
        user_investment,
        initial_user_loaded: false,
    };

    if (account) {
        const investment = await crowdfunding.userInvestment(account);

        investment_details = {
            user_investment: investment.investAmount.toString(),
            initial_user_loaded: true,
        };
    }

    return {
        event_id,
        hardcap,
        start_date,
        end_date,
        total_raised,
        num_whitelisted,
        num_invested: num_invested.length,
        vesting_address,
        ...investment_details,
    };
};

export const getCrowdfundingInfo = async (
    contract: ProjectContract,
    contract_manager: IContractManager,
    chainId: number,
    event_id: number,
    user?: User,
    user_investment = '0',
): Promise<Crowdfunding> => {
    if (!contract?.address) return;

    const ABI = JSON.parse(contract.abi).filter(
        ({ name }) => name !== 'Initialized',
    );

    let crowdfunding;

    if (contract_manager) {
        crowdfunding = contract_manager.getContractByAddressAndABI(
            contract.address,
            ABI,
            chainId,
        );
    }

    const uses_merkle_root = !!crowdfunding?.contract?.setMerkleRoot;

    try {
        if (uses_merkle_root) {
            return getCrowdfundingDetails(
                event_id,
                crowdfunding.contract,
                user?.wallet_address,
            );
        } else {
            return getLegacyCrowdfundingDetails(
                event_id,
                crowdfunding.contract,
                user?.wallet_address,
                user_investment,
            );
        }
    } catch (error) {
        return null;
    }
};

export const getTokenMetrics = async (
    slug: string,
): Promise<ProjectToken | null> => {
    type TokenMetrics = {
        retrieveProject: Project;
    };

    try {
        const { retrieveProject } = (await api_client.query({
            query: PROJECT.GET_ONE,
            variables: { slug },
        })) as TokenMetrics;

        return retrieveProject.token;
    } catch (error) {
        return null;
    }
};
