import React from 'react';
import { Typography as UITypography } from '@decub8/ui';

import { Typography } from '@src/components/Typography';
import { EventType } from '@src/ts/constants';
import { Project, ProjectEvent } from '@src/ts/interfaces';

import { token_claim_info } from './constants';
import { RenderChallenge } from './RenderChallenge';

export const LearnToEarn = React.forwardRef<
    HTMLDivElement,
    { project: Project; event: ProjectEvent }
>(({ project, event }, ref) => {
    return (
        <div className="my-12" ref={ref}>
            {event.type === EventType.TokenClaim && (
                <div className={`space-y-5 my-12 mb-12`}>
                    <UITypography allBold as="h2" size="3xl">
                        Token claim event
                    </UITypography>

                    <UITypography variant="secondary" className="mb-4">
                        {token_claim_info}
                    </UITypography>
                </div>
            )}
            <div>
                {event.contract.address && (
                    <div>
                        <Typography type="h1" className="mb-4">
                            Learn to Earn
                        </Typography>
                        <Typography type="p" style="secondary" className="mb-4">
                            Now for the fun stuff—time to put your knowledge of{' '}
                            {project.name} to the test. You’ll have to answer
                            the following questions. The answers can be found in
                            the resources linked below.
                        </Typography>

                        <RenderChallenge event={event} project={project} />
                    </div>
                )}
            </div>
        </div>
    );
});
