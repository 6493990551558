import { Contract } from '@ethersproject/contracts';
import dayjs from 'dayjs';

import { api_client } from '@src/bootstrap';
import {
    isAmaEventFinished,
    isAmaEventLive,
} from '@src/components/HomepageComponents/MainEventCardContainer/utils';
import { isHardcapMet } from '@src/components/Project/Event/utils';
import { getCrowdfundingInfo } from '@src/components/Project/util';
import { CONTRACT, DEFAULT_CHAIN_ID } from '@src/config';
import { IDO_REG_CUTOFF_MINS } from '@src/constants';
import { IContractManager } from '@src/contracts/manager';
import { CONFIG, PROJECT } from '@src/services';
import {
    ConfigKey,
    ContentSectionType,
    EventChipStatus,
    EventType,
} from '@src/ts/constants';
import {
    BackendConfigItem,
    IContentSection,
    Project,
    ProjectEvent,
} from '@src/ts/interfaces';

export const getMinReferrerTier = async () => {
    const {
        retrieveConfig: { value },
    } = await api_client.query<{ retrieveConfig: BackendConfigItem }>({
        query: CONFIG.GET_CONFIG_ITEM,
        variables: {
            key: ConfigKey.MinTierForRefer,
        },
    });

    return parseInt(value);
};

export const getMinTokensForTierWithNoMin = async () => {
    const {
        retrieveConfig: { value },
    } = await api_client.query<{ retrieveConfig: BackendConfigItem }>({
        query: CONFIG.GET_CONFIG_ITEM,
        variables: {
            key: ConfigKey.MinTokensForTierNoMin,
        },
    });

    return parseInt(value);
};

const secs_per_minute = 60;
const secs_per_hour = 3600;
const secs_per_day = 86400;
const secs_per_month = secs_per_day * 30;

export const getDuration = (seconds: number) => {
    if (!seconds) return undefined;

    if (seconds < secs_per_minute)
        return `${seconds} ${seconds > 1 ? 'Seconds' : 'Second'}`;

    if (seconds >= secs_per_minute && seconds < secs_per_hour)
        return `${Math.floor(seconds / secs_per_minute)} ${
            Math.floor(seconds / secs_per_minute) > 1 ? 'Minutes' : 'Minute'
        }`;

    if (seconds >= secs_per_hour && seconds < secs_per_day)
        return `${Math.floor(seconds / secs_per_hour)} ${
            Math.floor(seconds / secs_per_hour) > 1 ? 'Hours' : 'Hour'
        }`;

    if (seconds >= secs_per_day && seconds < secs_per_month)
        return `${Math.floor(seconds / secs_per_day)} ${
            Math.floor(seconds / secs_per_day) > 1 ? 'Days' : 'Day'
        }`;

    return `${Math.floor(seconds / secs_per_month)} ${
        Math.floor(seconds / secs_per_month) > 1 ? 'Months' : 'Month'
    }`;
};

// keep in mind: slug is name of the Project
export const getProjectData = async (slug: string): Promise<Project> => {
    const { retrieveProject: project } = (await api_client.query({
        query: PROJECT.GET_ONE,
        variables: {
            slug,
        },
        fetchPolicy: 'network-only',
    })) as { retrieveProject: Project };

    return {
        ...project,
        events: (project.events || []).map((event) => ({
            ...event,
            id: parseInt(event.id.toString()),
        })),
    };
};

export const getIsUserRegistered = async (
    event_id: number,
): Promise<boolean> => {
    return api_client
        .query<{
            isRegisteredForWhitelist: boolean;
        }>({
            query: PROJECT.IS_REGISTERED_FOR_WHITELIST,
            variables: {
                event_id,
            },
            fetchPolicy: 'network-only',
        })
        .then(({ isRegisteredForWhitelist: is_registered }) => is_registered)
        .catch(() => false);
};

export const getIsUidRegisteredForWhiteList = async (
    event_id: number,
    user_id: string,
): Promise<boolean> => {
    const res = await api_client.query<{
        isUidRegisteredForWhitelist: boolean;
    }>({
        query: PROJECT.IS_UID_REGISTERED_FOR_WHITELIST,
        variables: {
            event_id: parseInt(event_id.toString()), // is actually a string for some reason
            user_id,
        },
        fetchPolicy: 'network-only',
    });
    return res.isUidRegisteredForWhitelist;
};

export const getEventStatus = async (
    event: ProjectEvent,
    announced: boolean,
    contract_manager: IContractManager,
    user_id?: string,
): Promise<EventChipStatus> => {
    const { contract, start_date, type } = event || {};

    const event_id = parseInt(event?.id.toString()); // actually a string for some reason

    const today = dayjs();

    const start = dayjs(Number(start_date) * 1000);
    const end_date = getEventEndDate(event);
    const cutoff = start.subtract(
        event?.type === EventType.Crowdfunding ? IDO_REG_CUTOFF_MINS : 0,
        'minutes',
    );

    const has_started = today.isAfter(dayjs(Number(event?.start_date) * 1000));
    const is_event_over = today.isAfter(end_date);
    const has_reached_cutoff = today.isAfter(cutoff);

    const payment_token =
        CONTRACT.PaymentToken[event?.chainId || DEFAULT_CHAIN_ID];

    const has_contract = contract?.abi && contract?.address;
    const is_crowdfunding = type === EventType.Crowdfunding;

    if (!announced || !event || !event?.contract?.address || event.is_hidden)
        return EventChipStatus.ComingSoon;

    if (
        !is_crowdfunding &&
        has_contract &&
        today.isBefore(start) &&
        !event.is_hidden
    ) {
        return EventChipStatus.NowLive;
    }

    if (has_contract && today.isBefore(start) && is_crowdfunding) {
        // only check if user is whitelisted if the event has not started, contract exists and user is logged in
        const registered = user_id
            ? await getIsUidRegisteredForWhiteList(event_id, user_id)
            : false;

        if (registered) return EventChipStatus.Whitelisted;

        if (has_reached_cutoff && !registered) {
            return EventChipStatus.WhitelistClosed;
        }

        return EventChipStatus.WhitelistOpen;
    } else if (
        is_crowdfunding &&
        has_contract &&
        has_started &&
        today.isBefore(
            start.add(
                event.event_details?.durations.reduce((a, b) => a + b, 0),
                'seconds',
            ),
        )
    ) {
        const { total_raised, hardcap } = await getCrowdfundingInfo(
            event.contract,
            contract_manager,
            event.chainId,
            event_id,
        );

        // check if hardcap has been met before the time limit is reached
        const has_reached_hardcap = isHardcapMet(
            payment_token.decimals,
            hardcap,
            total_raised,
        );

        if (has_reached_hardcap) return EventChipStatus.EventCompleted;

        return EventChipStatus.NowLive;
    } else if (is_event_over) {
        return EventChipStatus.EventCompleted;
    } else {
        return EventChipStatus.ComingSoon;
    }
};

// returns a dayjs object
export const getEventEndDate = (event: ProjectEvent) => {
    // Convert the start date to a dayjs object
    const start = dayjs(Number(event?.start_date) * 1000);

    // if its token claim the end date is the start_date
    if (event?.type === EventType.TokenClaim) {
        return start;
    }

    // add up all the durations
    const total_duration = event?.event_details?.durations.reduce(
        (acc, cur) => acc + cur,
        0,
    );

    // end date for crowdfunding events
    return start.add(total_duration, 'seconds');
};

export const getHomepageEvents = async (): Promise<{
    main_event: IContentSection | null;
    secondary_events: IContentSection | null;
}> => {
    try {
        const {
            retrieveMainEvent: main_event,
            retrieveSecondaryEvents: secondary_events,
        } = (await api_client.query({
            query: PROJECT.RETRIEVE_HOMEPAGE_EVENTS,
            fetchPolicy: 'network-only',
            variables: {
                main_event_name: ContentSectionType.MainEvent,
                secondary_events_name: ContentSectionType.SecondaryEvents,
            },
        })) as {
            retrieveMainEvent: IContentSection | null;
            retrieveSecondaryEvents: IContentSection | null;
        };

        return { main_event, secondary_events };
    } catch (error) {
        console.error('Error retrieving homepage events:', error);
        return { main_event: null, secondary_events: null };
    }
};

export const getAmaEvent = (
    next_project_event: Project,
    secondary_events: Project[],
) => {
    const projects = [next_project_event, ...secondary_events];

    for (const project of projects) {
        const event = project?.events && project?.events[0];
        if (!event || !event?.ama_event) continue;

        const ama_event = event?.ama_event;
        if (isAmaEventFinished(ama_event)) continue;

        return {
            ama_event: {
                title: ama_event.title,
                date: ama_event.start_date
                    ? dayjs(Number(ama_event.start_date) * 1000).format(
                          'D MMMM, H:mm',
                      )
                    : undefined,
                youtube_url: ama_event.link || undefined,
                show_ama_live: isAmaEventLive(ama_event),
                is_complete: isAmaEventFinished(ama_event),
            },
        };
    }
    return { ama_event: null };
};

export const getNumInvested = async (event_contract: Contract | null) => {
    return (await event_contract.getParticipants()).length;
};
