import React, { createContext, useEffect } from 'react';
import { pContent } from 'dcb-config';

import { ProjectImageType } from '@src/ts/constants';
import { Content } from '@src/ts/interfaces';
import { getPlatformImages } from '@src/utils/getters';

interface Image {
    id: ProjectImageType;
    url: string;
}

// Create a new context
export const ContentContext = createContext<Content | undefined>(pContent);

export const ContentProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    // Load project content from config
    // const CONTENT: Content = pContent as Content;
    const [content, setContent] = React.useState<Content>(pContent as Content);

    useEffect(() => {
        const fetchImages = async () => {
            const res = (await getPlatformImages()) as Image[];
            const nextContent = { ...content };

            for (const img of res) {
                const u = content.baseImgUrl + '/content/' + img.url;
                switch (img.id) {
                    case ProjectImageType.BlogIcon:
                        nextContent.blogIcon = u;
                        break;
                    case ProjectImageType.InvestIcon:
                        nextContent.investIcon = u;
                        break;
                    case ProjectImageType.ReferralWelcomeExplore:
                        nextContent.referralConfig.welcomeExploreImage = u;
                        break;
                    case ProjectImageType.ReferralWelcomeClaim:
                        nextContent.referralConfig.welcomeClaimImage = u;
                        break;
                    case ProjectImageType.ReferralHeader:
                        nextContent.referralConfig.headerImage = u;
                        break;
                    case ProjectImageType.ReferralBanner:
                        nextContent.referralConfig.bannerImage = u;
                        break;
                    case ProjectImageType.StakingBannerDesktop:
                        nextContent.stakingBanner.desktop = u;
                        break;
                    case ProjectImageType.StakingBannerMobile:
                        nextContent.stakingBanner.mobile = u;
                        break;
                }
            }
            setContent(nextContent);
        };

        fetchImages();
    }, []);

    return (
        <ContentContext.Provider value={content}>
            {children}
        </ContentContext.Provider>
    );
};

export const useContent = () => {
    const context = React.useContext(ContentContext);
    if (context === undefined) {
        throw new Error('useContent must be used within a ContentProvider');
    }
    return context;
};
