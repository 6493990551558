import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { RequirementsCardProps } from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';

import { api_client } from '@src/bootstrap/index';
import { CONTENT, DEFAULT_CHAIN_ID } from '@src/config';
import { BaseTokenSymbol, getBaseTokenDecimals } from '@src/contracts/index';
import { setUser } from '@src/features/auth';
import { useAppDispatch } from '@src/hooks/index';
import { useContent } from '@src/hooks/useContent';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { USER } from '@src/services/user';
import { ITier, User } from '@src/ts/interfaces';
import { parseBalance } from '@src/utils/web3';

import { ReferralTabs, TierRewards } from '..';

import { useFilteredButtonOptions } from './util';

export const useRequirementsCard = (
    user: User,
    wallet_verified: boolean,
    nav: ReferralTabs,
    min_referrer_tier: number,
    setShowCongratsModal: (show: boolean) => void,
    last_key_with_zero_reward: number,
    is_tier_rewards_uniform: boolean,
    tier_rewards: TierRewards,
    setClaimTier: (tier: number) => void,
    setRequirementsComplete: (show: boolean) => void,
    setNav: (nav: ReferralTabs) => void,
    setClaimedReward: (show: boolean) => void,
): RequirementsCardProps => {
    const {
        _userTier,
        _tiers,
        contract_manager,
        user_status: { has_verified_wallet, has_verified_identity },
    } = useGlobalContext();
    const [loading, setLoading] = useState(false);
    const { referralConfig } = useContent();
    const dispatch = useAppDispatch();
    const is_bonus = nav === ReferralTabs.Bonus;

    const current_tier =
        (_userTier?.id >= 0 && _tiers[_userTier?.id]) || ({} as ITier);

    const has_min_tier = is_bonus ? true : current_tier.id >= min_referrer_tier;

    const button_options = useFilteredButtonOptions(
        has_verified_identity,
        wallet_verified,
        has_min_tier,
        user,
        min_referrer_tier,
        is_tier_rewards_uniform,
        last_key_with_zero_reward,
        current_tier,
        is_bonus,
    );

    const getReferralId = async () => {
        try {
            const { generateReferralId: user } = await api_client.mutate<{
                generateReferralId: User;
            }>({
                mutation: USER.GENERATE_REFERRAL_ID,
            });

            dispatch(setUser(user));

            setRequirementsComplete(true);
        } catch (err) {
            toast.error(
                'An error occured while generating referral id, please try again later.',
            );
        }
    };

    const has_referrral_id = is_bonus ? true : !!user.referral_id;

    const continue_btn_text = `Claim ${parseBalance(
        tier_rewards[last_key_with_zero_reward + 1] || BigNumber.from(0),
        getBaseTokenDecimals(),
    )} ${BaseTokenSymbol}`;

    const handleRewardsUniformClaim = async () => {
        setLoading(true);
        const { claimReferralReward: tx_hash } = await api_client.mutate<{
            claimReferralReward: string;
        }>({
            mutation: USER.CLAIM_REFERRAL_REWARD,
        });
        const tx = await contract_manager
            .getProvider(DEFAULT_CHAIN_ID)
            .waitForTransaction(tx_hash, 1);

        if (tx.status === 0)
            throw new Error(
                'An error occured during the transaction, please try again later',
            );
        toast.info('Transaction successfully complete.');

        setShowCongratsModal(true);
        setClaimTier(_userTier?.id + 1); //todo check if this is correct, what if _userTier.id is max tier here?
        setLoading(false);
        setNav(ReferralTabs.InviteEarn);
        setClaimedReward(true);
        setRequirementsComplete(false);
    };

    useEffect(() => {
        if (
            has_min_tier &&
            has_verified_wallet &&
            has_verified_identity &&
            has_referrral_id &&
            button_options.length === 0
        )
            setRequirementsComplete(true);
    }, [
        has_verified_wallet,
        has_verified_identity,
        has_referrral_id,
        has_min_tier,
        button_options,
    ]);

    return {
        button_options:
            button_options.length === 0 ? undefined : button_options,
        banner_title: is_bonus
            ? 'You truly unlimited reward is ready for you!'
            : 'Invite friends, earn together on their tier upgrade!',

        handleMoreClick: () =>
            window.open(
                CONTENT.referralConfig.learnMoreUrl,
                '_blank',
                'norefferer',
            ),
        continue_btn: {
            handleClick:
                is_tier_rewards_uniform && is_bonus
                    ? handleRewardsUniformClaim
                    : getReferralId,
            text:
                is_tier_rewards_uniform && is_bonus
                    ? continue_btn_text
                    : is_bonus
                    ? undefined
                    : 'Get the referral link',
            loading: loading,
            has_success_color: is_tier_rewards_uniform && is_bonus,
            disabled: button_options.length !== 0,
        },
        header_image: referralConfig.headerImage,
        is_invite_tab: !is_bonus,
    };
};
