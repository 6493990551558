import { DEFAULT_CHAIN_ID } from '@src/config';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';

export const useSwitchChain = () => {
    const { connectedChain, setChainID, settingChain } = useWeb3Onboard();

    // func that takes in chain id and returns if chainId is same as connected chain
    // TODO: should be renamed to isConnectedCorrectChain
    const isConnectedChainEventChain = (chainId: number) => {
        if (!connectedChain) return true;

        const connected = parseInt(connectedChain?.id, 16);
        // convert chainId to actual number
        const chainIdNumber = chainId
            ? parseInt(chainId.toString())
            : DEFAULT_CHAIN_ID;

        return connected === chainIdNumber;
    };

    return { isConnectedChainEventChain, setChainID, settingChain };
};
