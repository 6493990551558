import { useEffect, useState } from 'react';

import { getTokenMetrics } from '@src/components/Project/util';
import { ProjectToken } from '@src/ts/interfaces';
import { CrowdfundingType } from '@src/ts/types';

const makeSaleLabel = (key: string) => {
    switch (key) {
        case 'initial_market_cap_ex_liquidity':
            return 'Initial market cap excluding liquidity';
        case 'initial_market_cap':
            return 'Initial market cap';
        case 'fully_diluted_valuation':
            return 'Fully diluted market cap';
        case 'circulating_supply_tge':
            return 'Circulating supply TGE';
        case 'circulating_supply_tge_percent':
            return 'Circulating supply TGE %';
        case 'total_supply':
            return 'Total supply';
        default:
            return 'Unknown';
    }
};

const makeAllocationLabel = (key) => {
    switch (key) {
        case CrowdfundingType.Seed:
            return 'Seed';
        case CrowdfundingType.Private:
            return 'Private Sale';
        case CrowdfundingType.Public:
            return 'Public';
        case CrowdfundingType.Total:
            return 'Total';
        case CrowdfundingType.KOLs:
            return 'KOLs';
        default:
            console.log('Unknown Crowdfunding Type:', key);
            return 'Unknown';
    }
};

export const useTokenMetrics = (slug: string) => {
    const [token_data, setTokenData] = useState<ProjectToken | null>(null);
    const [token_sale_data, setTokenSaleData] = useState(null);
    const [token_allocation_data, setTokenAllocationData] = useState(null);

    useEffect(() => {
        if (!slug && slug === '') return;

        const makeRequest = async () => {
            const tokenMetrics = await getTokenMetrics(slug);
            setTokenData(tokenMetrics);
        };
        makeRequest();
    }, [slug]);

    useEffect(() => {
        if (token_data && token_data.token_allocation_info) {
            const allocationData = Object.values(
                token_data.token_allocation_info,
            )
                .filter((value) => value.amount && value.percentage) // Ensure valid data only otherwise breaks
                .map((value) => ({
                    label: makeAllocationLabel(value.type),
                    value: value.amount,
                    percentage: value.percentage,
                }));

            setTokenAllocationData(allocationData);
        } else {
            setTokenAllocationData([]);
        }
    }, [token_data]);

    useEffect(() => {
        if (token_data) {
            const saleData = Object.entries(token_data)
                .map(([key, value]) => ({
                    label: makeSaleLabel(key),
                    value,
                }))
                .filter(
                    ({ value, label }) =>
                        value !== null &&
                        value !== undefined &&
                        value !== '' &&
                        label !== 'Unknown',
                ); // Filter out invalid values otherwise breaks

            setTokenSaleData(saleData);
        } else {
            setTokenSaleData([]);
        }
    }, [token_data]);

    return {
        token_sale_data,
        token_allocation_data,
    };
};
