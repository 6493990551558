import { useContext, useMemo } from 'react';
import { useRouter } from 'next/router';

import { CONTENT } from '@src/config';
import { GlobalContext } from '@src/hooks/useGlobalContext';
import { ITier, User } from '@src/ts/interfaces';

export const useFilteredButtonOptions = (
    identity_verified: boolean,
    wallet_verified: boolean,
    has_min_tier: boolean,
    user: User,
    min_referrer_tier: number,
    is_tier_rewards_uniform: boolean,
    last_key_with_zero_reward: number,
    current_tier: ITier,
    is_bonus: boolean,
) => {
    const {
        _setTierDrawerOpen,
        user_status: {
            has_pending_verification_status,
            is_wallet_pending,
            has_verified_wallet,
        },
    } = useContext(GlobalContext);
    const router = useRouter();

    const can_claim_reward = current_tier.id > last_key_with_zero_reward;

    return useMemo(
        () =>
            [
                {
                    label: has_pending_verification_status
                        ? 'Identification verification - Pending'
                        : 'Verify identity',
                    handleClick: () =>
                        router.push('/verify/identity?referred=true'),
                    loader: has_pending_verification_status,
                    disabled: has_pending_verification_status,
                    complete: identity_verified,
                },
                {
                    label: is_wallet_pending
                        ? 'Wallet verification - Pending'
                        : 'Verify wallet',
                    handleClick: () =>
                        router.push(`/verify/wallet?referred=true`),
                    loader: is_wallet_pending,
                    disabled: is_wallet_pending,
                    complete: has_verified_wallet,
                },
                {
                    label: is_tier_rewards_uniform
                        ? is_bonus
                            ? `Get ${CONTENT.tiers.tierConstants[
                                  last_key_with_zero_reward + 1
                              ].name.toUpperCase()} tier or above`
                            : `Get ${CONTENT.tiers.tierConstants[
                                  min_referrer_tier
                              ].name.toUpperCase()} tier or above`
                        : `Get ${CONTENT.tiers.tierConstants[
                              min_referrer_tier
                          ].name.toUpperCase()} tier or above`,
                    handleClick: () => {
                        _setTierDrawerOpen(true);
                    },
                    // has_min_tier always true for bonus tab
                    complete: is_tier_rewards_uniform
                        ? (current_tier?.id !== undefined
                              ? current_tier?.id
                              : -1) >= 0
                            ? is_bonus
                                ? can_claim_reward
                                : has_min_tier
                            : false
                        : has_min_tier,
                },
            ].filter((e) => !e.complete),
        [
            has_pending_verification_status,
            identity_verified,
            router,
            is_wallet_pending,
            has_verified_wallet,
            min_referrer_tier,
            has_min_tier,
            current_tier,
            is_tier_rewards_uniform,
        ],
    );
};
