import { useEffect, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { log } from '@logtail/next';
import dayjs from 'dayjs';

import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { ContractType } from '@src/ts/constants';
import { User } from '@src/ts/interfaces';

export const useWalletInfo = (user: User) => {
    const { account, connect } = useWeb3Onboard();
    const [is_whitelisted, setIsWhitelisted] = useState(false);
    const [cooldown, setCooldown] = useState<string | null>(null);
    const { wallet_address } = user || {};
    const { contract_manager } = useGlobalContext();

    const connected_wallet_is_selected =
        account && account?.toLowerCase() === wallet_address?.toLowerCase();

    useEffect(() => {
        let isMounted = true;
        if (wallet_address) {
            const walletStore = contract_manager.getContract(
                ContractType.WalletStore,
            );
            walletStore.contract
                .isVerified(wallet_address)
                .then((res: boolean) => isMounted && setIsWhitelisted(res))
                .catch((err: unknown) =>
                    log.error('Error checking if wallet is verified', err),
                );

            walletStore.contract
                .users(wallet_address)
                .then(async (res: { lastAccessTime: BigNumber }) => {
                    const waitTime = await walletStore.contract.waitTime();

                    const whenAvailable = dayjs(
                        res.lastAccessTime.add(waitTime).mul(1000).toNumber(),
                    );

                    if (dayjs().isBefore(whenAvailable) && isMounted) {
                        setCooldown(whenAvailable.toISOString());
                    } else if (isMounted) {
                        setCooldown(null);
                    }
                })
                .catch((err: unknown) =>
                    log.error("Error checking user's wallet info", err),
                );
        }

        return () => {
            isMounted = false;
        };
    }, [wallet_address, contract_manager]);

    return {
        account,
        connect,
        is_whitelisted,
        cooldown,
        connected_wallet_is_selected,
    };
};
