import { useEffect, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { log } from '@logtail/next';

import { NETWORKS } from '@src/config';
import { useForceUpdate } from '@src/hooks';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';

import { useGlobalContext } from '../useGlobalContext';

/**
 * Get the balance of the networm native token for a given address
 * @param chainId - the chain id of the token
 * @param is_native - whether the token is native to the chain (e.g. ETH, BNB)
 */
export const useNativeTokenBalance = (
    chainId: number,
): { balance: string; symbol: string } => {
    const [balance, setBalance] = useState('0');
    const { contract_manager } = useGlobalContext();
    const updater = useForceUpdate(5000);

    const { account } = useWeb3Onboard();

    useEffect(() => {
        if (account) {
            const promise = contract_manager
                .getProvider(chainId)
                .getBalance(account);

            promise
                .then((amount: BigNumber) => {
                    setBalance(amount.toString());
                })
                .catch((err) =>
                    log.error(
                        `error getting balance for ${NETWORKS[chainId].symbol} (Chain ID:${chainId})`,
                        err,
                    ),
                );
        } else {
            setBalance('0');
        }
    }, [account, chainId, updater]);

    return {
        balance,
        symbol: NETWORKS[chainId].symbol,
    };
};
