import { Card, Typography } from '@decub8/ui';

export interface EventCompleteSummaryProps
    extends React.HTMLAttributes<HTMLElement> {
    amount_raised: string;
    percentage_raised: string;
    whitelisted_participants: string;
}

export const EventCompleteSummary: React.FC<EventCompleteSummaryProps> = ({
    whitelisted_participants,
    amount_raised,
    percentage_raised,
}) => {
    return (
        <div className="relative flex items-center justify-center gap-5">
            <Card
                surface="two"
                className="w-full h-[174px] flex flex-col items-center justify-center relative overflow-hidden"
            >
                <div className="overlay-gradient"></div>
                <Typography className="relative z-10" size="2xl" allBold>
                    {amount_raised}
                </Typography>
                <Typography variant="secondary" size="xs" className="z-10">
                    {percentage_raised} Raised
                </Typography>
            </Card>
            <Card
                surface="two"
                className="w-full h-[174px] flex flex-col items-center justify-center relative overflow-hidden"
            >
                <div className="absolute w-full h-full overlay-gradient z-0" />
                <Typography allBold size="2xl" className="relative z-10">
                    {whitelisted_participants}
                </Typography>
                <Typography variant="secondary" size="sm" className="z-10">
                    Whitelisted
                </Typography>
                <div className="blurred-line my-3"></div>
            </Card>
        </div>
    );
};
