export enum ContentAlignment {
    Centre = 'Centre',
    Left = 'Left',
}

export enum MarketingEventType {
    Telegram = 'Telegram',
    Youtube = 'Youtube',
}

export enum ConfigKey {
    MinTokensForTierNoMin = 'min_tokens_for_tier_with_no_min',
    MinTierForRefer = 'min_tier_for_refer',
    EventPlaceholderCardImg = 'event_placeholder_card_img',
}

export enum ConfigType {
    string = 'string',
    number = 'number',
}

export enum ContentSectionType {
    LandingPageVideo = 'LandingPageVideo',
    PartnerSection = 'PartnerSection',
    LearningSection = 'LearningSection',
    FAQ = 'FAQ',
    HeroSection = 'HeroSection',
    MainEvent = 'MainEvent',
    SecondaryEvents = 'SecondaryEvents',
    ApplyForIDO = 'ApplyForIDO',
    GetStartedSection = 'GetStartedSection',
    AdditionalInfoSection = 'AdditionalInfoSection',
}

export enum ContractType {
    PaymentToken = 'PaymentToken',
    BaseToken = 'BaseToken',
    ERC20 = 'ERC20',

    TierMigrator = 'TierMigrator',
    Investments = 'Investments',
    NFT = 'NFT',
    Tiers = 'Tiers',
    WalletStore = 'WalletStore',
    MultiCall = 'MultiCall',
    UniswapV2Pair = 'UniswapV2Pair',
    PlatformVesting = 'PlatformVesting',
    EventFactory = 'EventFactory',
    // legacy event factory
    LegacyEventFactory = 'LegacyEventFactory',
    // staking
    StakingV1 = 'StakingV1',
    LegacyCompoundStaking = 'LegacyCompoundStaking',
    LegacyVault = 'LegacyVault',
    MultiAssetStaking = 'MultiAssetStaking',
    LegacyLiquidityStaking = 'LegacyLiquidityStaking',
    // new staking
    Vault = 'Vault',
    CompoundStaking = 'CompoundStaking',
    LiquidityStaking = 'LiquidityStaking',
}

export enum EventType {
    Crowdfunding = 'Crowdfunding',
    TokenClaim = 'TokenClaim',
}

// enum for the main event card stages
export enum EventStage {
    PreWhitelist = 'PreWhitelist',
    WhitelistOpen = 'WhitelistOpen',
    SnapshotPeriod = 'SnapshotPeriod',
    GaFcfsOpen = 'GaFcfsOpen',
    Complete = 'Complete',
}

// corresponds to the enum in the backend
// for the main event card and project card chips
export enum ChipType {
    Private = 'Private',
    Seed = 'Seed',
    Public = 'Public',
    TokenClaim = 'Learn 2 Earn',
    Standard = 'Standard',
    Exclusive = 'Exclusive',
    Acceleration = 'Acceleration',
    Incubation = 'Incubation',
}

export enum CrowdfundingType {
    Private = 'Private',
    Seed = 'Seed',
    Public = 'Public',
}

export enum IdentityVerifiedStatus {
    VERIFIED = 'VERIFIED',
    UNVERIFIED = 'UNVERIFIED',
    PENDING = 'PENDING',
    FAILED = 'FAILED',
}

export enum LinkPosition {
    Both = 'both',
    Footer = 'footer',
    Header = 'header',
}

export enum ProjectStatus {
    ACTIVE = 'ACTIVE',
    APPROVED = 'APPROVED',
    COMPLETE = 'COMPLETE',
    PENDING = 'PENDING',
}

export enum StrategyType {
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
}

export enum UserRole {
    Admin = 'Admin',
    ProjectAdmin = 'ProjectAdmin',
    ProjectManager = 'ProjectManager',
    ComplianceOfficer = 'ComplianceOfficer',
    UserManager = 'UserManager',
    ProductManager = 'ProductManager',
    Manager = 'Manager',
    Innovator = 'Innovator',
    Investor = 'Investor',
    None = 'None',
}

export const UserGroup = {
    Admin: [UserRole.Admin],
    ProjectAdmin: [UserRole.Admin, UserRole.ProjectAdmin],
    ProductManager: [UserRole.Admin, UserRole.ProductManager, UserRole.Manager],
    ProjectManager: [
        UserRole.Admin,
        UserRole.ProjectAdmin,
        UserRole.ProjectManager,
        UserRole.Manager,
    ],
    ComplianceOfficer: [UserRole.Admin, UserRole.ComplianceOfficer],
    UserManager: [
        UserRole.Admin,
        UserRole.ComplianceOfficer,
        UserRole.UserManager,
        UserRole.Manager,
    ],
    TeamAuthenticated: [
        UserRole.Admin,
        UserRole.ProjectAdmin,
        UserRole.ProjectManager,
        UserRole.ProductManager,
        UserRole.ComplianceOfficer,
        UserRole.UserManager,
        UserRole.Manager,
    ],
    Authenticated: [
        UserRole.Admin,
        UserRole.ProjectAdmin,
        UserRole.ProjectManager,
        UserRole.ProductManager,
        UserRole.ComplianceOfficer,
        UserRole.UserManager,
        UserRole.Manager,
        UserRole.Innovator,
        UserRole.Investor,
    ],
    NoAuth: [
        UserRole.Admin,
        UserRole.ProjectAdmin,
        UserRole.ProjectManager,
        UserRole.ProductManager,
        UserRole.ComplianceOfficer,
        UserRole.UserManager,
        UserRole.Manager,
        UserRole.Innovator,
        UserRole.Investor,
        UserRole.None,
    ],
};

// corresponds to the enum in the backend
export enum StatisticType {
    UniqueParticipantsIDO = 'UniqueParticipantsIDO',
    UniqueParticipantsL2E = 'UniqueParticipantsL2E',
    ATHValueDistributed = 'ATHValueDistributed',
    TotalAmountRaised = 'TotalAmountRaised',
    EventsCompleteIDO = 'EventsCompleteIDO',
    EventsCompleteL2E = 'EventsCompleteL2E',
    TasksCompleted = 'TasksCompleted',
    AverageATH = 'AverageATH',
}

export enum ProjectImageType {
    HowToParticipateImage = 'HowToParticipateImage',
    BlogIcon = 'BlogIcon',
    InvestIcon = 'InvestIcon',
    ReferralWelcomeExplore = 'ReferralWelcomeExplore',
    ReferralWelcomeClaim = 'ReferralWelcomeClaim',
    ReferralHeader = 'ReferralHeader',
    ReferralBanner = 'ReferralBanner',
    LandingPageVideoImageDesktop = 'LandingPageVideoImageDesktop',
    LandingPageVideoImageMobile = 'LandingPageVideoImageMobile',
    StakingBannerDesktop = 'StakingBannerDesktop',
    StakingBannerMobile = 'StakingBannerMobile',
}

export enum StakingModalTabs {
    Deposit = 'Deposit',
    Withdraw = 'Withdraw',
    Bounty = 'Bounty',
}

export enum EventChipStatus {
    ComingSoon = 'Coming soon',
    NowLive = 'Now live',
    WhitelistOpen = 'Whitelist open',
    WhitelistClosed = 'Whitelist closed',
    Whitelisted = 'Whitelisted',
    EventCompleted = 'Event completed',
    GANowLive = 'GA now live',
}

export const BADGE_COLOR = {
    [EventChipStatus.NowLive]: 'accent',
    [EventChipStatus.WhitelistOpen]: 'purple',
    [EventChipStatus.WhitelistClosed]: 'orange',
    [EventChipStatus.Whitelisted]: 'green',
    [EventChipStatus.ComingSoon]: 'yellow',
    [EventChipStatus.GANowLive]: 'accent',
    [EventChipStatus.EventCompleted]: 'surface-two-green',
};
