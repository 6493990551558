import React, { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';
import { AppProps } from 'next/app';

import { store } from '@src/bootstrap';
import { Layout } from '@src/components';
import { RECAPTCHA_ID } from '@src/config';
import { useGoogleTag, useLatestBuild, useLoginRedirects } from '@src/hooks';
import { ContentProvider } from '@src/hooks/useContent';
import { GlobalContextProvider } from '@src/hooks/useGlobalContext';
import { InvestmentContextProvider } from '@src/hooks/usePortfolioContext';
import web3onboard, { Web3onboardProvider } from '@src/hooks/useWeb3Onboard';

import 'tailwindcss/tailwind.css';
import '@src/app.scss';
import 'react-toastify/dist/ReactToastify.css';

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
    useGoogleTag();
    useLatestBuild();
    useLoginRedirects();

    useEffect(() => {
        const walletsSub = web3onboard.state.select('wallets');
        const { unsubscribe } = walletsSub.subscribe((wallets) => {
            const connectedWallets = wallets.map(({ label }) => label);
            window.localStorage.setItem(
                'connectedWallets',
                JSON.stringify(connectedWallets),
            );
        });
        return unsubscribe;
    }, []);

    return (
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_ID}>
            <Web3onboardProvider>
                <ContentProvider>
                    <Provider store={store}>
                        <GlobalContextProvider>
                            <InvestmentContextProvider>
                                <Layout>
                                    <Component {...pageProps} />
                                </Layout>
                            </InvestmentContextProvider>
                        </GlobalContextProvider>
                    </Provider>
                </ContentProvider>
            </Web3onboardProvider>
        </GoogleReCaptchaProvider>
    );
}

export default MyApp;
