import { Contract } from '@ethersproject/contracts';
import { log } from '@logtail/next';
import {
    Action,
    createSlice,
    PayloadAction,
    ThunkDispatch,
} from '@reduxjs/toolkit';

import { RootState } from '@src/bootstrap/store';
import { staking_contract_versions } from '@src/constants';
import { IContractManager } from '@src/contracts';
import { StakingModalTabs } from '@src/ts/constants';
import { StakingPool } from '@src/ts/interfaces';
import { StakingVersion } from '@src/ts/types';

import {
    getCompoundPool,
    getLegacyCompoundPool,
    getLegacyPool,
    getLiquidityPool,
    getMultiAssetPool,
} from './utils';

interface StakingState {
    loading: boolean;
    current_pool: number;
    pools: StakingPool[];
    modal_state: string;
    deposit_amount: string;
    token1_deposit_amount: string;
    withdraw_percent: number;
    initial: boolean;
}

const initialState = {
    loading: false,
    current_pool: 0,
    pools: [],
    modal_state: StakingModalTabs.Deposit,
    deposit_amount: '0',
    token1_deposit_amount: '0',
    withdraw_percent: 50,
} as StakingState;

const stakingSlice = createSlice({
    name: 'staking',
    initialState,
    reducers: {
        setWithdrawPercent(state: StakingState, action: PayloadAction<number>) {
            state.withdraw_percent = action.payload;
        },
        setLoading(state: StakingState, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setInitial(state: StakingState, action: PayloadAction<boolean>) {
            state.initial = action.payload;
        },
        setCurrentPool(state: StakingState, action: PayloadAction<number>) {
            state.current_pool = action.payload;
        },
        setPool(state: StakingState, action: PayloadAction<StakingPool>) {
            const idx = state.pools.findIndex(
                ({ id }) => id === action.payload.id,
            );
            state.pools[idx] = action.payload;
        },
        setPools(state: StakingState, action: PayloadAction<StakingPool[]>) {
            state.pools = action.payload;
        },
        setModalState(state: StakingState, action: PayloadAction<string>) {
            state.modal_state = action.payload;
        },
        setDepositAmount(state: StakingState, action: PayloadAction<string>) {
            state.deposit_amount = action.payload;
        },
        setToken1DepositAmount(
            state: StakingState,
            action: PayloadAction<string>,
        ) {
            state.token1_deposit_amount = action.payload;
        },
    },
});

const getPool = async (
    idx: number,
    account: string,
    version: StakingVersion,
    staking: Contract,
    chain_id: number,
    contract_manager: IContractManager,
) => {
    switch (version) {
        case 'compound':
            return getCompoundPool(
                idx,
                account,
                staking,
                chain_id,
                contract_manager,
            );
        case 'legacy-compound':
            return getLegacyCompoundPool(
                idx,
                account,
                staking,
                contract_manager,
            );
        case 'multi-asset':
            return getMultiAssetPool(idx, account, staking, contract_manager);
        case 'liquidity':
            return getLiquidityPool(
                idx,
                account,
                staking,
                chain_id,
                contract_manager,
            );
        case 'legacy-liquidity':
            return getLiquidityPool(
                idx,
                account,
                staking,
                chain_id,
                contract_manager,
            );
        default:
            return getLegacyPool(idx, account, staking, contract_manager);
    }
};

export const updatePool = (
    id: number,
    version: StakingVersion,
    account: string,
    contract_manager: IContractManager,
    initial_pool?: StakingPool,
): ((
    dispatch: ThunkDispatch<RootState, void, Action>,
    getState: () => RootState,
) => void) => {
    return (
        dispatch: ThunkDispatch<RootState, void, Action>,
        getState: () => RootState,
    ): void => {
        dispatch(setLoading(true));

        const pool =
            initial_pool || getState().staking.pools.find((p) => p.id === id);

        const staking = contract_manager.getContract(
            staking_contract_versions[version],
            pool.chain_id,
        );

        getPool(
            pool.contract_idx,
            account,
            version,
            staking.contract,
            pool.chain_id,
            contract_manager,
        )
            .then((user_data) => {
                dispatch(setPool({ ...pool, ...user_data }));
                dispatch(setLoading(false));
            })
            .catch((err) => {
                dispatch(setLoading(false));
                log.error('Error updating staking pool', err);
            });
    };
};

export const updatePools = (
    account: string,
    pools: StakingPool[],
    contract_manager: IContractManager,
): ((
    dispatch: ThunkDispatch<RootState, void, Action>,
    getState: () => RootState,
) => void) => {
    return (dispatch: ThunkDispatch<RootState, void, Action>): void => {
        dispatch(setPools(pools));
        dispatch(setLoading(true));

        Promise.all(
            pools.map(async (p) => {
                const staking = contract_manager.getContract(
                    staking_contract_versions[p.type],
                    p.chain_id,
                );

                const user_data = await getPool(
                    p.contract_idx,
                    account,
                    p.type,
                    staking.contract,
                    p.chain_id,
                    contract_manager,
                );

                return user_data;
            }),
        )
            .then((all_user_data) => {
                const updated_pools = pools.map((p, idx) => ({
                    ...p,
                    ...all_user_data[idx],
                }));

                dispatch(setPools(updated_pools));
                dispatch(setLoading(false));
            })
            .catch((err) => {
                dispatch(setLoading(false));
                log.error('Error updating staking pools', err);
            });
    };
};

export const {
    setCurrentPool,
    setWithdrawPercent,
    setLoading,
    setPool,
    setPools,
    setInitial,
    setModalState,
    setDepositAmount,
    setToken1DepositAmount,
} = stakingSlice.actions;
export default stakingSlice.reducer;
