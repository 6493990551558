import { useEffect, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { log } from '@logtail/next';

import { useForceUpdate } from '@src/hooks';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { ContractType } from '@src/ts/constants';

import { useGlobalContext } from '../useGlobalContext';

/**
 * Get the balance of a token for a given address
 * @param address - the address of the token
 * @param chainId - the chain id of the token
 * @param is_native - whether the token is native to the chain (e.g. ETH, BNB)
 */
export const useTokenBalance = (
    address: string,
    chainId: number,
    is_native = false,
): string => {
    const [balance, setBalance] = useState('0');
    const { contract_manager } = useGlobalContext();
    const updater = useForceUpdate(5000);

    const { account } = useWeb3Onboard();

    useEffect(() => {
        if (address && account && contract_manager) {
            const promise = is_native
                ? contract_manager.getProvider(chainId).getBalance(account)
                : contract_manager
                      .getContractByAddress(
                          address,
                          ContractType.ERC20,
                          chainId,
                      )
                      .contract.balanceOf(account);

            promise
                .then((amount: BigNumber) => {
                    setBalance(amount.toString());
                })
                .catch((err) =>
                    log.error(
                        `error getting token balance for ${address} and ${chainId} (is native token: ${is_native})`,
                        err,
                    ),
                );
        } else {
            setBalance('0');
        }
    }, [account, address, chainId, updater, contract_manager]);

    return balance;
};
