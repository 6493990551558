import React, { useContext } from 'react';
import { Button, Modal, Typography } from '@decub8/ui';
import { useRouter } from 'next/router';

import { ModalType } from '@src/components/Project/Event/EventModals';
import { useAppSelector } from '@src/hooks/index';
import { GlobalContext } from '@src/hooks/useGlobalContext';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';

const KycStepsCompleted: React.FC = () => {
    const { showKycCompleted, setShowKycCompleted, setEventModalState } =
        useContext(GlobalContext);

    const user = useAppSelector((state) => state.auth.user);

    const has_submitted_wallet = !!user?.wallet_address;

    const router = useRouter();

    const { account } = useWeb3Onboard();

    // TODO: variable for allocation pop up to now show when directed back to project page
    const is_on_project_page = router.asPath.includes('project');

    const onClose = () => {
        setShowKycCompleted(false);

        // TODO: removed the allocation pop up
        if (!has_submitted_wallet && account && is_on_project_page) {
            setEventModalState({
                modal_type: ModalType.VerifyWallet,
                show: true,
            });
        } else if (!has_submitted_wallet && !account && is_on_project_page) {
            setEventModalState({
                modal_type: ModalType.VerifyConnectWallet,
                show: true,
            });
        }
    };

    return (
        <Modal
            noClose
            show={showKycCompleted}
            onClose={() => setShowKycCompleted(false)}
        >
            <Typography className="mb-8" allBold size="4xl">
                Data submitted
            </Typography>
            <Typography className="mb-8" variant="secondary">
                Thank you for submitting your verification data. <br /> You’ll
                receive an email with the result, or you can check it in ‘My
                Profile’.
            </Typography>
            <Typography className="mb-8" variant="secondary">
                Once your ID is verified, you can participate in an event.
            </Typography>

            <Button className="w-full" onClick={onClose}>
                Got it
            </Button>
        </Modal>
    );
};

export default KycStepsCompleted;
