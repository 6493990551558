import React, { useContext, useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { useRefreshTokenAuth } from '@src/bootstrap/api-client';
import { setRefreshed } from '@src/features/layout';
import { useAppDispatch, useAppSelector } from '@src/hooks';
import { GlobalContext } from '@src/hooks/useGlobalContext';

import AllocationPopUpModal from '../Modal/AllocationPopUpModal';
import KycStepsCompleted from '../Modal/KycCompleteModal';

import Container from './Container';
import Footer from './Footer';
import Header from './Header';
import { default as Meta } from './Meta';
import { PageLoader } from '.';

const UpgradeTierDrawer = dynamic(import('@src/components/UpgradeTierDrawer'));
const ConnectionErrorModal = dynamic(
    import('@src/components/Modal/ConnectionErrorModal'),
);
const IncorrectNetworkModal = dynamic(
    import('@src/components/Modal/IncorrectNetworkModal'),
);
const SafetyModal = dynamic(import('@src/components/Modal/SafetyModal'));
const StakingModal = dynamic(import('@src/components/Staking/StakingModal'));
const VerificationModal = dynamic(
    import('@src/components/Modal/VerificationModal'),
);
export const Layout: React.FC<{ children?: React.ReactNode }> = ({
    children,
}) => {
    useRefreshTokenAuth();
    const router = useRouter();
    const [loading, setLoading] = useState(false);

    const { showAllocationPopup, showKycCompleted } = useContext(GlobalContext);

    useEffect(() => {
        const handleRouteChangeStart = () => {
            window.scrollTo(0, 0); // scroll to the top of the page on route change
            setLoading(true);
        };
        const handleRouteChangeComplete = () => setLoading(false);

        router.events.on('routeChangeStart', handleRouteChangeStart);
        router.events.on('routeChangeComplete', handleRouteChangeComplete);

        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart);
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
        };
    }, [router.events]);

    const { refreshed } = useAppSelector((state) => state.layout);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!refreshed) {
            dispatch(setRefreshed(true));
        }
    }, []);

    const headerRef = useRef<HTMLDivElement>(null);
    const [headerHeight, setHeaderHeight] = useState(0);

    useEffect(() => {
        const updateHeaderHeight = () => {
            if (headerRef.current) {
                setHeaderHeight(headerRef.current.clientHeight);
            }
        };

        // Initial calculation
        updateHeaderHeight();

        const resizeObserver = new ResizeObserver(updateHeaderHeight);
        resizeObserver.observe(headerRef.current);

        // Recalculate on window resize
        window.addEventListener('resize', updateHeaderHeight);

        return () => {
            window.removeEventListener('resize', updateHeaderHeight);
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <div data-testid="layout">
            <div
                ref={headerRef}
                className={`fixed w-full top-0 ${
                    showAllocationPopup || showKycCompleted
                        ? 'z-[10]'
                        : 'z-[60]'
                }`}
            >
                <Header />
            </div>
            <main
                className="relative"
                style={{ paddingTop: `${headerHeight}px` }}
            >
                <Container>
                    {loading && (!showAllocationPopup || !showKycCompleted) ? (
                        <PageLoader text="" />
                    ) : (
                        children
                    )}
                </Container>
            </main>
            <Meta />
            <Footer />
            <UpgradeTierDrawer />
            {/* not sure if the connection error modal being used  */}
            <ConnectionErrorModal />
            <IncorrectNetworkModal />
            <SafetyModal />
            <StakingModal />
            <VerificationModal />
            <AllocationPopUpModal />
            <KycStepsCompleted />
            <ToastContainer theme="dark" position="bottom-right" />
        </div>
    );
};

export { Content } from './Content';
export { Loader, PageLoader } from './Loader';
export { Meta };
