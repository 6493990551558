import { FC } from 'react';
import { Chip, Typography } from '@decub8/ui';

import { ProjectEvent } from '@src/ts/interfaces';

export const EventHeader: FC<{
    event: ProjectEvent;
}> = ({ event }) => {
    return (
        <div className="bg-surface-level-two px-5 py-[16px] rounded-lg flex items-center space-x-5 low-border">
            <Typography allBold size="2xl">
                {event.name}
            </Typography>
            {event.is_hidden && (
                <Chip size="xs" className="pl-3 pr-3">
                    hidden
                </Chip>
            )}
        </div>
    );
};
