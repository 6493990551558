import React from 'react';
import { Card, Countdown, Typography } from '@decub8/ui';

export const CountdownCardEventWidget: React.FC<{
    target_date: string;
    whitelist_open: boolean;
    has_accent_countdown?: boolean;
}> = ({ target_date, whitelist_open, has_accent_countdown }) => {
    return (
        <div>
            <Typography variant="secondary" className="mb-[10px]">
                {whitelist_open
                    ? 'Whitelist closes in'
                    : 'Guaranteed allocation round opens in'}
            </Typography>
            <Card surface="two" className={`relative overflow-hidden`}>
                <div className="overlay-gradient"></div>
                <div className="mx-auto">
                    <Countdown
                        is_smaller
                        target_date={target_date}
                        has_accent_countdown={has_accent_countdown}
                    />
                </div>
            </Card>
        </div>
    );
};
