import React from 'react';
import { FAQSection as FAQSectionUI } from '@decub8/ui';

import { IContentSection } from '@src/ts/interfaces';

export const FAQSection: React.FC<{
    section: IContentSection;
    className?: string;
}> = ({ section, className }) => {
    return (
        <FAQSectionUI
            className={className}
            faqs={section.data?.map(({ key, value }) => ({
                question: key,
                answer: value,
            }))}
        />
    );
};
