export const header_height = 82;

export const scrollIntoView = (
    ref: React.RefObject<HTMLElement>,
    offset: number = 0,
): void => {
    if (ref.current) {
        const elementPosition =
            ref.current.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });
    }
};
