import React, { useContext, useMemo } from 'react';
import { Typography } from '@decub8/ui';
import Link from 'next/link';

import { ArrowRight } from '@src/components/Icon';
import { useAppSelector } from '@src/hooks/index';
import { GlobalContext } from '@src/hooks/useGlobalContext';
import { EventType, IdentityVerifiedStatus } from '@src/ts/constants';
import { IUserTier, ProjectEvent, User } from '@src/ts/interfaces';
import { getCorrectTier } from '@src/utils/getters';

import { EventRegister } from './Register';

const classes =
    'text-left w-full p-4 bg-surface-level-two hover:bg-surface-level-three flex rounded border border-border border-opacity-[0.03]';

export const useQualifySteps = (
    user: User,
    user_tier: IUserTier,
    setTierDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>,
    min_tier: number,
    setShowAllocationModal: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const steps = useMemo(
        () => [
            {
                name: 'Register or sign in',
                complete: !!user,
                href: '/login',
                type: 'link',
            },
            {
                name: 'Verify ID',
                complete:
                    !user ||
                    user.identity_verified ===
                        IdentityVerifiedStatus.VERIFIED ||
                    user.identity_verified === IdentityVerifiedStatus.PENDING,
                href: '/verify/identity',
                type: 'link',
            },
            {
                name: 'ID verification pending',
                complete:
                    !user ||
                    user.identity_verified ===
                        IdentityVerifiedStatus.VERIFIED ||
                    user.identity_verified ===
                        IdentityVerifiedStatus.UNVERIFIED ||
                    user.identity_verified === IdentityVerifiedStatus.FAILED,
                href: '/account',
                type: 'link',
            },
            {
                name: 'Verify a wallet',
                complete: !user || user.wallet_address,
                href: '/verify/wallet',
                type: 'link',
            },
            {
                name: 'Get an investor tier',
                complete: !user || user_tier?.flag,
                type: 'button',
                onClick: () => setTierDrawerOpen(true),
            },
            {
                name: 'Upgrade your investor tier',
                complete: !user || !user_tier?.flag || user_tier.id >= min_tier,
                type: 'button',
                onClick: () => setShowAllocationModal(true),
            },
        ],
        [user, user_tier, min_tier, setTierDrawerOpen],
    );

    return steps.filter(({ complete }) => !complete);
};

export const Qualify: React.FC<{
    event: ProjectEvent;
    min_tier: number;
    registered: boolean;
}> = ({ event, min_tier }) => {
    const { _userTier, _setTierDrawerOpen, _tiers, setShowAllocationModal } =
        useContext(GlobalContext);
    const { user } = useAppSelector((state) => state.auth);

    const steps_to_show = useQualifySteps(
        user,
        _userTier,
        _setTierDrawerOpen,
        min_tier,
        setShowAllocationModal,
    );

    return (
        <div className="space-y-4">
            {steps_to_show.map(({ name, href, type, onClick }) =>
                type === 'link' ? (
                    <Link
                        href={href}
                        key={`${name} + ${href}`}
                        className={classes}
                    >
                        <div className="flex-1">
                            <Typography
                                variant="custom"
                                className="hover:text-primary text-secondary"
                            >
                                {name}
                            </Typography>
                        </div>
                        <div className="text-accent">
                            <ArrowRight />
                        </div>
                    </Link>
                ) : (
                    <>
                        {name === 'Upgrade your investor tier' && (
                            <Typography size="sm" variant="secondary">
                                To whitelist for this event, you must be at
                                least a{' '}
                                <span className="text-white text-bold">
                                    {' '}
                                    {
                                        getCorrectTier(min_tier, _tiers)?.name
                                    }{' '}
                                </span>{' '}
                                tier.
                            </Typography>
                        )}
                        <button
                            className={classes}
                            onClick={onClick}
                            key={name}
                        >
                            <div className="flex-1">
                                <Typography className="text-secondary">
                                    {name}
                                </Typography>
                            </div>
                            <div className="text-accent">
                                <ArrowRight />
                            </div>
                        </button>
                    </>
                ),
            )}
            {event.type === EventType.Crowdfunding && (
                <EventRegister event={event} />
            )}
        </div>
    );
};
