import { useEffect, useMemo, useState } from 'react';

import { ITier } from '@src/ts/interfaces';
import { getMinLimitForTiers } from '@src/utils/getters';

export const useUserAllocationMultiplier = (tiers: ITier[], userTier) => {
    // minimum dcb power is the minimum power of the first tier
    const [minimum_dcb_power, setMinimumDcbPower] = useState<number | null>(0);

    const user_allocation_multiplier = useMemo(() => {
        return userTier && minimum_dcb_power
            ? Number((userTier.power / minimum_dcb_power).toFixed(1))
            : 1;
    }, [minimum_dcb_power, userTier]);

    useEffect(() => {
        if (minimum_dcb_power) return;

        getMinLimitForTiers(tiers)
            .then((min) => {
                setMinimumDcbPower(min);
            })
            .catch((err) =>
                console.log('Error fetching minimum DCB power for tiers', err),
            );
    }, [minimum_dcb_power, tiers]);

    return {
        minimum_dcb_power,
        user_allocation_multiplier,
    };
};
