import React from 'react';

import { useAppSelector } from '@src/hooks/index';
// import { EventStageForMock, mockedProject } from '@src/mock-event-flow/project';
import { UserGroup } from '@src/ts/constants';
import { ProjectItemProps } from '@src/ts/props';

import { EventContainer } from './Event/EventContainer';
import { EventModals } from './Event/EventModals';
// import { EventModals } from './Event/EventModals/index';
import { ProjectContextProvider } from './context';
import ProjectOverview from './Overview';

export const ProjectComponent: React.FC<ProjectItemProps> = ({ project }) => {
    const learn_to_earn_ref = React.createRef<HTMLDivElement>();
    const { user } = useAppSelector((state) => state.auth);

    // const mock_project = mockedProject(EventStageForMock.GARoundOpen);
    // const {
    //     events: [event],
    // } = mock_project;

    const {
        events: [event],
    } = project;

    const has_event = !!event;
    const should_display_event = has_event
        ? event?.is_hidden
            ? UserGroup.ProjectManager.includes(user?.role)
            : true
        : false;

    const scrollToLearnToEarn = () =>
        learn_to_earn_ref.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });

    return (
        <ProjectContextProvider
            // project={mocked_project}
            project={project}
            event={event}
            children={
                <>
                    {' '}
                    <div className="flex flex-col lg:flex-row lg:gap-5">
                        <div
                            className={`w-full ${
                                should_display_event ? 'lg:w-[780px]' : ''
                            }`}
                        >
                            <ProjectOverview
                                // project={mock_project}
                                project={project}
                                ref={learn_to_earn_ref}
                                scrollToLearnToEarn={scrollToLearnToEarn}
                                event={event}
                                should_display_event={should_display_event}
                            />
                        </div>
                        {should_display_event && (
                            <EventContainer
                                project_id={project.id}
                                scrollToLearnToEarn={scrollToLearnToEarn}
                                event={event}
                            />
                        )}
                    </div>
                    <EventModals />
                </>
            }
        />
    );
};
