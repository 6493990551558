import { IdentityVerifiedStatus } from '@src/ts/constants';

import { useAppSelector } from '..';

export const useUserStatus = (): {
    has_verified_identity: boolean;
    has_verified_wallet: boolean;
    has_pending_verification_status: boolean;
    is_wallet_pending: boolean;
    has_failed_verification_status: boolean;
} => {
    const user = useAppSelector((state) => state.auth.user);
    const { wallet_verified } = useAppSelector((state) => state?.auth) || {};

    const has_verified_identity =
        user?.identity_verified === IdentityVerifiedStatus.VERIFIED;

    const has_pending_verification_status =
        user?.identity_verified === IdentityVerifiedStatus.PENDING;

    const has_verified_wallet = !!user?.wallet_address && wallet_verified;

    const is_wallet_pending = user?.wallet_address && !wallet_verified;

    const has_failed_verification_status =
        user?.identity_verified === IdentityVerifiedStatus.FAILED;

    return {
        has_verified_identity,
        has_pending_verification_status,
        has_verified_wallet,
        is_wallet_pending,
        has_failed_verification_status,
    };
};
