import { ContractType } from '@src/ts/constants';
import { FormData } from '@src/ts/interfaces';

import { StakingVersion } from './ts/types';

/* eslint-disable-next-line */
declare let window: any;

export const DEFAULT_IDO_ROUND_DURATIONS = [3600 * 22, 3600, 3600];

export const IDO_REG_CUTOFF_MINS = 30;

export const is_browser = typeof window !== 'undefined';

export const primary_button_classes =
    'py-2 px-6 rounded-3xl focus:outline-none ring-opacity-75 ring-pink-500 text-white bg-accent hover:bg-accent-hover text-center';

export const TOKEN_DECIMALS = {
    HFT: 9,
    $FRBK: 8,
};

export const ETHER_UNITS = {
    9: 'gwei',
    18: 'ether',
};

export const login_form_data: FormData[] = [
    {
        name: 'email',
        label: 'Email',
        type: 'email',
        stage: 0,
    },
    {
        name: 'password',
        label: 'Password',
        type: 'password',
        stage: 0,
    },
    {
        name: 'secret',
        label: 'Two-step verification code',
        type: 'text',
        placeholder: '--- ---',
        stage: 1,
    },
];

export const kyc_form_data: FormData[] = [
    {
        name: 'first_name',
        label: 'First name',
        type: 'text',
        placeholder: 'Enter your first name',
    },
    {
        name: 'last_name',
        label: 'Last name',
        type: 'text',
        placeholder: 'Enter your last name',
    },
    {
        name: 'dob',
        label: 'Date of birth',
        type: 'date',
        placeholder: 'Enter your date of birth',
    },
];

export const provider_is_meta_mask =
    is_browser &&
    typeof window.web3 !== 'undefined' &&
    window.web3.currentProvider.isMetaMask === true;

export const staking_contract_versions: {
    [key in StakingVersion]: ContractType;
} = {
    compound: ContractType.CompoundStaking,
    v1: ContractType.StakingV1,
    'legacy-compound': ContractType.LegacyCompoundStaking,
    'multi-asset': ContractType.MultiAssetStaking,
    'legacy-liquidity': ContractType.LegacyLiquidityStaking,
    liquidity: ContractType.LiquidityStaking,
};
