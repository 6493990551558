import { useEffect, useMemo, useState } from 'react';
import type { PoolContainerProps, PoolProps } from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits, parseEther } from '@ethersproject/units';
import dayjs from 'dayjs';

import { AppDispatch } from '@src/bootstrap/store';
import { CONTRACT, NETWORKS } from '@src/config';
import { useAppDispatch, useAppSelector } from '@src/hooks';
import { useSwitchChain } from '@src/hooks/useSwitchChain';
import { StakingPool } from '@src/ts/interfaces';
import { formatBigNumber } from '@src/utils/format';
import {
    effectiveApy,
    getPoolMultiplier,
    getStakingVersions,
} from '@src/utils/staking';

import { findNft } from '../util';

import { handleOpenDrawer } from './utils';

type Capacity = {
    percentFilled?: number;
    staked?: string;
};
type Multiplier = {
    active?: boolean;
    amount?: string;
    boostReward?: string;
    name?: string;
};
type Stake = {
    amount?: string;
    symbol?: string;
};
type Tokens = {
    label?: string;
    parts: {
        image: string;
        name: string;
    }[];
};

const getMultiplier = (pool: StakingPool): Multiplier => {
    const { boost, has_boost } = getPoolMultiplier(pool);

    const { apr, nft_indexes, nft_multiplier_used } = pool;

    const apr_num = parseFloat(apr) + boost;

    const APR_with_boost = (isNaN(apr_num) ? 0 : apr_num.toFixed(2)) + '%';

    const [start, end] = nft_indexes || [0, 0];
    const nft_name = nft_multiplier_used ? findNft(start, end) : 'None';

    return nft_multiplier_used
        ? {
              active: has_boost,
              amount: `+${boost || 0}%`,
              boostReward: APR_with_boost,
              name: nft_name,
          }
        : undefined;
};

const getPoolCapacity = (pool: StakingPool): Capacity[] => {
    const {
        type,
        total_staked = ['0', '0'],
        hardcap,
        input_token = [
            CONTRACT.BaseToken[pool.chain_id],
            CONTRACT.BaseToken[pool.chain_id],
        ],
        end_date,
    } = pool;

    const expired = dayjs(end_date).diff(dayjs()) <= 0;

    const is_liquidity = ['legacy-liquidity', 'liquidity'].includes(type);

    const is_unlimited =
        is_liquidity ||
        BigNumber.from(hardcap).gte(parseEther('1000000000').toString());

    return input_token.map((token, idx) => {
        const raw =
            (Number(formatUnits(total_staked[idx], token.decimals)) /
                Number(formatUnits(hardcap, token.decimals))) *
            100;
        const percent =
            raw > 100 && raw !== 0 ? Math.floor(raw) : Math.ceil(raw);

        return {
            percentFilled:
                !is_unlimited && !expired ? Math.min(percent, 100) : undefined,
            staked: !expired
                ? `${formatBigNumber(total_staked[idx], token.decimals)} ${
                      token.symbol
                  }`
                : 'Pool expired',
        };
    });
};

const getRewardAmount = (pool: StakingPool): Stake[] => {
    const { has_stake, earned_reward = '0', reward_token } = pool;

    if (!has_stake) {
        return [
            {
                amount: '00.00',
                symbol: reward_token.symbol,
            },
        ];
    }

    return [
        {
            amount: formatBigNumber(earned_reward, reward_token.decimals),
            symbol: reward_token.symbol,
        },
    ];
};

const getStakeAmount = (pool: StakingPool): Stake[] => {
    const {
        user_stake = ['0', '0'],
        input_token = [
            CONTRACT.BaseToken[pool.chain_id],
            CONTRACT.BaseToken[pool.chain_id],
        ],
        has_stake,
    } = pool;

    if (!has_stake) {
        return input_token.map((token) => ({
            amount: '00.00',
            symbol: token.symbol,
        }));
    }

    return input_token.map((token, idx) => ({
        amount: formatBigNumber(user_stake[idx], token.decimals),
        symbol: token.symbol,
    }));
};

const getStakeTokens = (pool: StakingPool): Tokens[] => {
    const {
        reward_token,
        input_token = [
            CONTRACT.BaseToken[pool.chain_id],
            CONTRACT.BaseToken[pool.chain_id],
        ],
    } = pool;

    const input_token_name =
        input_token.length > 1
            ? input_token.map((token) => token.symbol).join(' / ')
            : input_token[0].symbol;

    const idx =
        input_token.length > 1 &&
        input_token[0].symbol.toUpperCase() ===
            reward_token.symbol.toUpperCase()
            ? 1
            : 0;

    const parts = [
        {
            image: `/icons/${input_token[idx]?.symbol?.toUpperCase()}.svg`,
            name: input_token_name,
        },
        ...(input_token.length > 1
            ? [
                  {
                      image: `/icons/${reward_token?.symbol?.toUpperCase()}.svg`,
                      name: reward_token.symbol,
                  },
              ]
            : []),
    ];

    return [
        {
            label: 'Stake & earn',
            parts,
        },
    ];
};

export const usePoolContainer = (
    pool: StakingPool,
    is_mobile: boolean,
): PoolContainerProps => {
    const { type } = pool;

    const isLoading = useAppSelector((state) => state.staking.loading);

    return {
        id: `${type}-staked-poolid-${pool.id}`,
        isEmpty: false,
        isLoading,
        is_mobile,
    };
};

export const usePoolItem = (pool: StakingPool): PoolProps => {
    const dispatch = useAppDispatch();

    const { isConnectedChainEventChain, setChainID } = useSwitchChain();

    return useMemo(
        () => getPoolItem(pool, dispatch),
        [pool, isConnectedChainEventChain, setChainID, dispatch],
    );
};

export const getPoolItem = (
    pool: StakingPool,
    dispatch: AppDispatch,
): PoolProps => {
    const {
        id,
        apr,
        type,
        has_stake,
        can_claim,
        lock_period,
        last_user_deposit,
        tier_boost,
        chain_id,
        rebate_percent,
    } = pool;

    const capacity = getPoolCapacity(pool);
    const multiplier = getMultiplier(pool);
    const tokens = getStakeTokens(pool);
    const userStake = getStakeAmount(pool);
    const userReward = getRewardAmount(pool);

    const lock_end_date = dayjs(last_user_deposit)
        .add(Number(lock_period), 'days')
        .format('MMM DD, YYYY');

    const APR = ['compound', 'legacy-compound'].includes(type)
        ? (effectiveApy(parseFloat(apr) / 100, 365) * 100).toFixed(2) + '%'
        : apr;

    const lock_end_date_prop =
        has_stake && (can_claim ? 'Unlocked' : lock_end_date);

    return {
        id: `${type}-staked`,
        capacity,
        lockTerm: {
            value: `${lock_period} days`,
            lockEndDate: lock_end_date_prop,
        },
        boosterChip: {
            active: !!tier_boost,
            amount: `+${tier_boost}%`,
        },
        multiplierChip: multiplier,
        reward: {
            label: ['compound', 'legacy-compound'].includes(type)
                ? 'Compounding'
                : 'Dynamic',
            value: APR,
        },
        stakingChip: {
            tokens,
        },
        userStake,
        userReward,
        // if the user is connected to the wrong chain, we want to prompt them to switch
        handlePoolClick: () => {
            handleOpenDrawer(dispatch, has_stake, can_claim, id);
        },
        network_logo: {
            image: NETWORKS[chain_id].network_logo,
            name: NETWORKS[chain_id].network_name,
            symbol: NETWORKS[chain_id].symbol,
        },
        rebate: rebate_percent ? rebate_percent + '%' : undefined,
    };
};

export const filterPoolsByStakingVersions = (
    pools: StakingPool[],
    versions: string[],
): StakingPool[] => {
    return pools.filter((pool) => versions.includes(pool.type));
};

export const useFilteredPools = (
    is_legacy: boolean,
): [StakingPool[], StakingPool[]] => {
    const { pools } = useAppSelector((state) => state.staking);

    const [has_stake, setHasStake] = useState([]);
    const [active_no_stake, setActiveNoStake] = useState([]);

    const versions = useMemo(() => getStakingVersions(is_legacy), [is_legacy]);

    const filtered_by_version = useMemo(
        () => filterPoolsByStakingVersions(pools, versions),
        [pools, versions],
    );

    useEffect(() => {
        setHasStake(filtered_by_version.filter(({ has_stake }) => has_stake));
        setActiveNoStake(
            filtered_by_version.filter(({ has_stake, end_date }) => {
                return !has_stake && dayjs().isBefore(dayjs(end_date));
            }),
        );
    }, [filtered_by_version]);

    return [has_stake, active_no_stake];
};
