import React from 'react';
import { InformationSection } from '@decub8/ui';

import { IContentSection } from '@src/ts/interfaces';

export const AdditionalInfoSection: React.FC<{
    section: IContentSection;
    className?: string;
}> = ({ section, className }) => {
    const data = section.images.map(({ name, text, url }) => {
        return {
            title: name || '',
            subtitle: text || '',
            logo: url || '',
        };
    });

    const props = {
        data,
    };

    return <InformationSection {...props} className={className} />;
};
