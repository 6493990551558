import { useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { IDO_REG_CUTOFF_MINS } from '@src/constants';
import { EventType } from '@src/ts/constants';

export const useEventDates = (
    start: Dayjs,
    durations: number[],
    event_type: EventType,
): {
    cutoff: Dayjs;
    ga_round_end_time: Dayjs;
    is_ga_round_over: boolean;
    has_reached_cutoff: boolean;
    has_started: boolean; // for crowdfunding not token claim
    is_fcfs_1: boolean;
    is_fcfs_2: boolean;
    is_event_end_date_has_passed: boolean;
    is_now_cutoff_period?: boolean;
    event_end_date: Dayjs;
} => {
    const cutoff = useMemo(
        () =>
            start.subtract(
                event_type === EventType.Crowdfunding ? IDO_REG_CUTOFF_MINS : 0,
                'minutes',
            ),
        [start],
    );

    const now = dayjs();

    const has_reached_cutoff = now.isAfter(cutoff);

    const is_now_cutoff_period = has_reached_cutoff && now.isBefore(start);

    const has_started = now.isAfter(start);

    const ga_round_end_time = start.add(durations[0], 'seconds');
    const fcfs1_round_end_time = start.add(
        durations[0] + durations[1],
        'seconds',
    );
    const fcfs2_round_end_time = start.add(
        durations[0] + durations[1] + durations[2],
        'seconds',
    );

    const is_ga_round_over = now.isAfter(ga_round_end_time);

    const is_fcfs_1 =
        now.isAfter(ga_round_end_time) && now.isBefore(fcfs1_round_end_time);

    const is_fcfs_2 =
        now.isAfter(fcfs1_round_end_time) && now.isBefore(fcfs2_round_end_time);

    const is_event_end_date_has_passed =
        event_type === EventType.Crowdfunding
            ? now.isAfter(
                  start.add(
                      durations.reduce((a, b) => a + b, 0),
                      'seconds',
                  ),
              )
            : now.isAfter(start);

    const event_end_date =
        event_type === EventType.Crowdfunding
            ? start.add(
                  durations.reduce((a, b) => a + b, 0),
                  'seconds',
              )
            : start;

    return {
        cutoff,
        ga_round_end_time,
        is_ga_round_over,
        has_reached_cutoff,
        has_started,
        is_fcfs_1,
        is_fcfs_2,
        is_event_end_date_has_passed,
        is_now_cutoff_period,
        event_end_date,
    };
};
