import React, { useMemo } from 'react';
import {
    Completed,
    FormData,
    Login,
    MultiStepModal,
    NotKYC,
    Typography,
} from '@decub8/ui';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';

import { useKYCForm } from '@src/components/Account/KYCForm/hooks';
import { CONTRACT, NETWORKS } from '@src/config';
import { kyc_form_data } from '@src/constants';
import { useLogin, useVerifyWallet } from '@src/hooks/pages';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { useKYC } from '@src/hooks/useKYC';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { generateSlug } from '@src/utils/format';
import { getTierOfUser } from '@src/utils/getters';

import { useProjectContext } from '../../context';

export enum ModalType {
    Login = 'Login',
    Completed = 'Completed',
    VerifyConnectWallet = 'VerifyConnectWallet',
    ConnectWallet = 'ConnectWallet',
    VerifyWallet = 'VerifyWallet',
    VerificationPending = 'VerificationPending',
    KYC = 'KYC',
    FailedVerification = 'VerificationFailed',
    UpgradeTier = 'UpgradeTier',
}

export const EventModals: React.FC = () => {
    const { event, project } = useProjectContext();

    const router = useRouter();

    const project_slug = generateSlug(project?.name || '');
    const event_slug = generateSlug(event?.name || '');

    const project_route = useMemo(() => {
        return `/project/${project_slug}/${event_slug}`;
    }, [project, event, project_slug, event_slug]);

    const { loadAndStartKyc } = useKYC({
        custom_complete_route: project_route,
    });

    const {
        state: form_state,
        loading,
        handleChange,
        handleUpdate,
    } = useKYCForm({
        onCompleteCallback: loadAndStartKyc,
    });
    const {
        handleWhitelist: handleVerifyWallet,
        account,
        loading: wallet_verify_loading,
    } = useVerifyWallet({
        is_event_widget_flow: true,
    });

    const {
        _tiers,
        _setTierDrawerOpen,
        setEventRegistrationContext,
        event_modal_state,
        setEventModalState,
        closeEventModal,
    } = useGlobalContext();

    const { event_details: { min_tier = 0 } = {}, id: event_id } = event || {};

    const required_tier = _tiers[min_tier];

    const { connect } = useWeb3Onboard();

    const { registerForWhitelist } = useProjectContext();

    // const { user } = useAppSelector((state) => state?.auth) || {};
    // const { wallet_verified } = useAppSelector((state) => state?.auth) || {};
    // const has_verified_identity =
    //     user?.identity_verified === IdentityVerifiedStatus.VERIFIED;
    // const has_pending_verification_status =
    //     user?.identity_verified === IdentityVerifiedStatus.PENDING;
    // const has_verified_wallet = !!user?.wallet_address && wallet_verified;
    // const has_wallet_submitted = !!user?.wallet_address;

    const { symbol: payment_symbol } =
        CONTRACT.PaymentToken[event?.chainId] || {};

    const login_props = useLogin({
        disable_routing: true,
        loginCallback: closeEventModal,

        createAccountCallback: () => {
            router.push('/register'),
                setEventRegistrationContext({ project_slug, event_slug });
            closeEventModal();
        },
    });

    const { show, modal_type } = event_modal_state;

    switch (modal_type) {
        case ModalType.Login:
            return (
                <Login
                    {...login_props}
                    show={show}
                    setShow={(show) =>
                        setEventModalState({
                            modal_type: ModalType.Login,
                            show,
                        })
                    }
                />
            );

        case ModalType.KYC:
            return (
                <NotKYC
                    Kyc_form_data={kyc_form_data as FormData[]}
                    show={show}
                    setShow={(show) =>
                        setEventModalState({
                            modal_type: ModalType.KYC,
                            show,
                        })
                    }
                    handleVerify={() => {
                        handleUpdate();
                        closeEventModal();
                    }}
                    loading={loading}
                    disabled={
                        loading ||
                        !form_state['first_name'] ||
                        !form_state['last_name'] ||
                        !form_state['dob']
                    }
                    state={form_state}
                    handleChange={handleChange}
                />
            );
        case ModalType.VerifyConnectWallet:
            return (
                <MultiStepModal
                    show={show}
                    setShow={(show) =>
                        setEventModalState({
                            modal_type: ModalType.VerifyConnectWallet,
                            show,
                        })
                    }
                    handleClick={() => {
                        closeEventModal();
                        connect()
                            .then(() =>
                                setEventModalState({
                                    modal_type: ModalType.VerifyWallet,
                                    show: true,
                                }),
                            )
                            .catch((err) => {
                                console.error(err);
                            });
                    }}
                    title="Whitelist for the event"
                    subtitle="Now let’s verify your wallet."
                    description={
                        <ul className=" list-disc pl-7 text-left text-primary text-sm sm:text-md">
                            <li>
                                Only one verified wallet is allowed per account.
                            </li>
                            <li>This wallet is exclusively used for events.</li>
                            <li>
                                All activities history will be linked solely to
                                this wallet.
                            </li>
                        </ul>
                    }
                    button_text="Connect wallet"
                />
            );

        case ModalType.VerifyWallet:
            return (
                <MultiStepModal
                    loading={wallet_verify_loading}
                    show={show}
                    setShow={(show) =>
                        setEventModalState({
                            modal_type: ModalType.VerifyWallet,
                            show,
                        })
                    }
                    handleClick={() => {
                        handleVerifyWallet().then(
                            async ({ pending, success }) => {
                                const user_tier = await getTierOfUser();

                                if (pending) {
                                    setEventModalState({
                                        modal_type:
                                            ModalType.VerificationPending,
                                        show: true,
                                    });
                                } else if (
                                    success &&
                                    !(user_tier?.id >= min_tier)
                                ) {
                                    closeEventModal();
                                } else if (
                                    success &&
                                    user_tier?.id >= min_tier
                                ) {
                                    // Close the modal if the whitelist operation succeeded
                                    return registerForWhitelist(
                                        Number(event_id),
                                        [],
                                    )
                                        .then(() =>
                                            setEventModalState({
                                                modal_type: ModalType.Completed,
                                                show: true,
                                            }),
                                        )
                                        .catch((err) => {
                                            console.log(err);
                                        });
                                } else {
                                    console.error(
                                        'Wallet verification failed or unexpected state.',
                                    );
                                }
                            },
                        );
                    }}
                    title="Whitelist for the event"
                    subtitle="Verify selected wallet"
                    description={
                        <Typography>
                            Click ‘Verify selected wallet’ below if this is the
                            wallet you prefer to use for events. Remember, only
                            one verified wallet is allowed per account.
                        </Typography>
                    }
                    button_text="Verify selected wallet"
                    wallet_address={account}
                    is_connected={!!account}
                />
            );
        case ModalType.VerificationPending:
            return (
                <MultiStepModal
                    show={show}
                    setShow={(show) =>
                        setEventModalState({
                            modal_type: ModalType.VerificationPending,
                            show,
                        })
                    }
                    handleClick={closeEventModal}
                    title="ID verification pending"
                    description={
                        <>
                            <span className="text-secondary text-center">
                                Your ID verification is still pending. You’ll
                                receive an email with the result, or you can
                                check the status in ‘My Profile.’
                            </span>
                            <br />
                            <br />
                            <span className="mt-6 text-secondary">
                                Once your ID is verified, please return to the
                                page to whitelist for the event.
                            </span>
                        </>
                    }
                    button_text="Got it"
                />
            );
        case ModalType.Completed:
            return (
                <Completed
                    show={show}
                    setShow={(show) =>
                        setEventModalState({
                            modal_type: ModalType.Completed,
                            show,
                        })
                    }
                    data={[
                        {
                            label: 'Network',
                            value:
                                NETWORKS[event?.chainId].network_name || 'TBA',
                        },
                        {
                            label: 'Network fees',
                            value: `$${NETWORKS[event?.chainId].symbol}`,
                        },
                        {
                            label: 'Funding currency',
                            value: `$${payment_symbol}`,
                        },
                    ]}
                    date_and_time={dayjs(
                        Number(event?.start_date) * 1000,
                    ).format('MMMM DD, YYYY / HH:mm')}
                />
            );
        case ModalType.UpgradeTier:
            return (
                <MultiStepModal
                    show={show}
                    setShow={(show) =>
                        setEventModalState({
                            modal_type: ModalType.UpgradeTier,
                            show,
                        })
                    }
                    handleClick={() => {
                        _setTierDrawerOpen(true);
                        closeEventModal();
                    }}
                    cancel_btn_text="Maybe later"
                    title="Whitelist for the event"
                    subtitle="Upgrade your tier."
                    description={
                        <span>
                            {` To whitelist for this event, you must be at least a
                            ${required_tier?.name} tier.`}
                        </span>
                    }
                    button_text="Upgrade your investor tier"
                />
            );

        case ModalType.FailedVerification:
            return (
                <MultiStepModal
                    show={show}
                    setShow={(show) =>
                        setEventModalState({
                            modal_type: ModalType.FailedVerification,
                            show,
                        })
                    }
                    handleClick={() => {
                        closeEventModal();
                    }}
                    title="ID verification unsuccessful"
                    description={
                        <span className="text-secondary">
                            Your ID verification has not been successful and
                            requires manual intervention. We have already
                            notified our support team for this matter. You’ll
                            receive an email with your support ticket number and
                            other information.
                        </span>
                    }
                    button_text="Got it"
                    no_close_btn
                />
            );

        default:
            return null;
    }
};
