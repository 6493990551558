import { Interface, Result } from '@ethersproject/abi';
import { BigNumber } from '@ethersproject/bignumber';
import { Contract, ContractInterface } from '@ethersproject/contracts';
import { getStatic } from '@ethersproject/properties';
import {
    JsonRpcProvider,
    Network,
    Networkish,
    StaticJsonRpcProvider,
} from '@ethersproject/providers';
import { fetchJson } from '@ethersproject/web';
import axios from 'axios';

import { NETWORKS } from '@src/config';
import { ContractType } from '@src/ts/constants';
import { ContractConfig } from '@src/ts/interfaces';
import { Evt } from '@src/utils/event';

import { getSignatureAndTs } from './util';

export interface IRpcManager {
    provider: CustomJsonRpcProvider;
    setProvider(endpoint: string): Promise<void>;
    getChainId(): number;
}

function getResult(payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: { code?: number; data?: any; message?: string };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    result?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): any {
    if (payload.error) {
        // @TODO: not any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const error: any = new Error(payload.error.message);
        error.code = payload.error.code;
        error.data = payload.error.data;
        throw error;
    }

    return payload.result;
}
class CustomJsonRpcProvider extends StaticJsonRpcProvider {
    private abiMap: Map<string, Interface>;

    constructor(rpcUrl: string) {
        super(rpcUrl);
        this.abiMap = new Map();
    }

    // Register a contract's ABI for debugging
    registerAbi(address: string, abi: string) {
        this.abiMap.set(address.toLowerCase(), new Interface(abi));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async send(method: string, params: Array<any>): Promise<any> {
        const [ts, signature] = getSignatureAndTs();
        const iface = this.abiMap.get(params[0]?.to?.toLowerCase());
        let decodedCall;
        if (iface && params[0]?.data) {
            try {
                // Decode the input call data
                decodedCall = iface.parseTransaction({
                    data: params[0].data,
                });
            } catch (e) {
                // console.log('Failed to decode input call data:', e);
            }
        }

        const request = {
            method: method,
            params: params,
            id: this._nextId++, // Increment id
            jsonrpc: '2.0',
            _ts: ts,
            _s: signature,
            functionName: decodedCall?.name || '',
        };

        // Call the original method, passing the modified request
        try {
            const result = await fetchJson(
                this.connection,
                JSON.stringify(request),
                getResult,
            );

            this.emit('debug', {
                action: 'response',
                request: request,
                response: result,
                provider: this,
            });

            return result;
        } catch (error) {
            console.error(
                'Error caught in send method',
                method,
                this.connection.url,
                error,
            );
            this.emit('debug', {
                action: 'response',
                error: error,
                request: request,
                provider: this,
            });
            return error;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async perform(method: string, params: any): Promise<any> {
        // extended base method to improve debugging
        const show_contract_calls = process.env.NEXT_PUBLIC_SHOW_CONTRACT_CALLS;
        if (
            method === 'call' &&
            params.transaction?.to &&
            show_contract_calls === 'true'
        ) {
            const iface = this.abiMap.get(params.transaction.to.toLowerCase());
            if (iface && params.transaction.data) {
                try {
                    // Decode the input call data
                    const decodedCall = iface.parseTransaction({
                        data: params.transaction.data,
                    });
                    if (decodedCall.name === 'aggregate') {
                        this.decodeMulticallData(
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            decodedCall.args[0].map((call: any) => ({
                                target: call.target,
                                func_name: call.callData,
                            })),
                        );
                    } else {
                        // console.log(
                        //     `Decoded Call:`,
                        //     {
                        //         to: params.transaction.to,
                        //         functionName: decodedCall.name,
                        //         args: decodedCall.args,
                        //     },
                        //     'using url:',
                        //     this.connection.url,
                        // );
                    }
                } catch (e) {
                    console.log('Failed to decode input call data:', e);
                }
            }
        }

        return super.perform(method, params);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public async decodeMulticallData(args: any): Promise<void> {
        // todo : make this work, currently not working as expected
        for (const arg of args) {
            if (!arg.target || !arg.func_name) {
                console.log(
                    'Invalid multicall data:',
                    arg,
                    'chain:',
                    this.connection.url,
                );
                continue;
            }
            const iface = this.abiMap.get(arg.target.toLowerCase());
            if (iface) {
                try {
                    const decodedCall = iface.parseTransaction({
                        data: arg.func_name,
                    });
                    console.log(`Decoded Call part of Multicall:`, {
                        to: arg.target,
                        functionName: decodedCall.name,
                        args: decodedCall.args,
                    });
                } catch (e) {
                    console.log(
                        'Failed to decode input multicall - call data:',
                        e,
                    );
                }
            }
        }
    }

    // called once on initing provider
    async _uncachedDetectNetwork(): Promise<Network> {
        // sleep for 0 seconds
        await new Promise((resolve) => setTimeout(resolve, 0));

        let chainId = null;
        try {
            chainId = await this.send('eth_chainId', []);
        } catch (error) {
            try {
                chainId = await this.send('net_version', []);
                // eslint-disable-next-line no-empty
            } catch (error) {}
        }

        if (chainId != null) {
            const getNetwork = getStatic<(network: Networkish) => Network>(
                this.constructor,
                'getNetwork',
            );
            try {
                return getNetwork(BigNumber.from(chainId).toNumber());
            } catch (error) {
                return undefined;
            }
        }

        return undefined;
    }
}

export interface IContractManager {
    /**
     * Function that gets a contract based on the type and chain
     * @param type ContractType enum
     * @param chainId chain id for the contract
     */
    getContract(type: ContractType, chainId?: number): ContractWrapper;
    /**
     * Function that gets a contracts address based on the type and chain
     * @param type ContractType enum
     * @param chainId chain id for the contract
     */
    getContractAddress(type: ContractType, chainId?: number): string;
    /**
     * Function that gets a contract based on the address and type
     * @param address contract address
     * @param type contract type
     * @param chainId chain id for the contract
     */
    getContractByAddress(
        address: string,
        type: ContractType,
        chainId?: number,
    ): ContractWrapper;
    /**
     * Function that gets a contract based on the address and abi
     * @param address contract address
     * @param abi contract abi
     * @param chainId chain id for the contract
     */
    getContractByAddressAndABI(
        address: string,
        abi: ContractInterface,
        chainId?: number,
    ): ContractWrapper;
    /**
     * Function that gets the contract interface based on the type
     * @param type ContractType enum
     */
    getContractInterface(type: ContractType): Interface;
    /**
     * Function that gets the provider based on the chain id
     * @param chainId chain id for the provider
     */
    getProvider(chainId: number): JsonRpcProvider;
    init(): Promise<void>;
    /**
     * Function that makes a multicall to the multicall contract
     * @param calls Array of calls to make
     * @param params Parameters for the multicall
     */
    multicall(calls: Call[], params?: MultiCallParams): Promise<Result[]>;
}

// RPC Manager class that manages the RPC provider
export class RpcManager implements IRpcManager {
    public NewProvider = new Evt<JsonRpcProvider>();
    public provider: CustomJsonRpcProvider;
    chain_id: number;

    constructor(chain_id: number) {
        this.chain_id = chain_id;
        const inital_endpoint = NETWORKS[chain_id].rpc[0];
        this.setProvider(inital_endpoint);
    }

    public async setProvider(endpoint: string): Promise<void> {
        if (!endpoint) {
            console.log(
                `[RPC MANAGER] No endpoint for chain ${this.chain_id}. Please check the RPC urls in the config`,
            );
            return;
        }

        try {
            const provider = new CustomJsonRpcProvider(endpoint);
            this.provider = provider;
        } catch (error) {
            console.log(
                `[RPC MANAGER] Endpoint for chain ${this.chain_id} is unhealthy. url: ${endpoint}`,
            );
        }
    }

    public getChainId(): number {
        return this.chain_id;
    }
}

// ContractWrapper class that wraps the ethers contract class
export class ContractWrapper {
    public contract: Contract;

    constructor(
        private address: string,
        private abi: ContractInterface,
        private provider: CustomJsonRpcProvider,
    ) {
        this.contract = new Contract(address, abi, provider);
        if (this.provider) {
            this.provider.registerAbi(this.address, JSON.stringify(this.abi));
        }
    }

    public async setProvider(provider: CustomJsonRpcProvider): Promise<void> {
        this.provider = provider;
        this.contract = new Contract(this.address, this.abi, provider);
        this.provider?.registerAbi(this.address, JSON.stringify(this.abi));
    }
}

// Manager for contracts on a single chain
export class SinlgeChainContractManager {
    private contracts: { [key: string]: ContractWrapper } = {};
    private tried_urls: string[] = [];

    constructor(private rpcManager: IRpcManager) {
        this.tried_urls.push(this.rpcManager.provider.connection.url);
        this.setProvider = this.setProvider.bind(this);
    }

    public async init(): Promise<void> {
        // check the current provider endpoint which has been set in the constructor of the rpcManager
        const isHealthy = await this.isRPCHealthy(
            this.rpcManager.provider.connection.url,
        );
        if (!isHealthy.good) {
            console.log(
                `[SINGLECHAIN_CONTRACT_MANAGER] Endpoint for chain ${this.rpcManager.getChainId()} is unhealthy. url: ${
                    this.rpcManager.provider?.connection.url
                }`,
            );

            this.tried_urls.push(this.rpcManager.provider?.connection.url);
            this.rpcManager.provider = undefined;
            for (const url of NETWORKS[this.rpcManager.getChainId()].rpc) {
                if (this.tried_urls.includes(url)) continue;
                this.tried_urls.push(url);

                const isHealthy = await this.isRPCHealthy(url);
                if (!isHealthy || !isHealthy.good) {
                    try {
                        this.tried_urls.push(url);
                    } catch (error) {
                        console.log(
                            `[SINGLECHAIN_CONTRACT_MANAGER] Endpoint for chain ${this.rpcManager.getChainId()} is unhealthy. url: ${url}`,
                        );
                        continue;
                    }
                } else {
                    console.log(
                        '[SINGLECHAIN_CONTRACT_MANAGER] Found healthy provider',
                        url,
                    );
                    const provider = new CustomJsonRpcProvider(url);
                    this.rpcManager.provider = provider;
                    this.tried_urls.push(url);
                    break;
                }
            }

            if (this.rpcManager.provider?.connection?.url === undefined) {
                console.log(
                    `[SINGLECHAIN_CONTRACT_MANAGER] No healthy provider for chain ${this.rpcManager.getChainId()} set. Please check the RPC urls in the config`,
                );
            }
        }

        // todo: rethink / check the stuff below
        await this.rpcManager.setProvider(
            this.rpcManager.provider?.connection.url,
        );

        await this.setProvider();
    }

    private async isRPCHealthy(
        url: string,
    ): Promise<{ good: boolean; chain_id: number; url: string }> {
        let res;
        try {
            const [ts, signature] = getSignatureAndTs();
            res = await axios.post(
                url,
                {
                    jsonrpc: '2.0',
                    id: 1,
                    method: 'eth_blockNumber',
                    params: [],
                    _ts: ts,
                    _s: signature,
                },
                {
                    timeout: 3000,
                },
            );

            if (res.data.error) {
                return {
                    good: false,
                    chain_id: this.rpcManager.getChainId(),
                    url,
                };
            }

            return {
                good: res.status === 200,
                chain_id: this.rpcManager.getChainId(),
                url,
            };
        } catch (error) {
            return {
                good: false,
                chain_id: this.rpcManager.getChainId(),
                url,
            };
        }
    }

    public getContract(
        address: string,
        abi: ContractInterface,
    ): ContractWrapper {
        if (this.contracts[address]) return this.contracts[address];
        this.contracts[address] = new ContractWrapper(
            address,
            abi,
            this.rpcManager.provider,
        );
        return this.contracts[address];
    }

    private async setProvider(): Promise<void> {
        for (const contract of Object.values(this.contracts)) {
            await contract.setProvider(this.rpcManager.provider);
        }
    }
}

export class ContractManager implements IContractManager {
    private managers: { [key: number]: SinlgeChainContractManager } = {};

    constructor(
        private rpcManagers: { [key: number]: RpcManager },
        private config: ContractConfig,
        private abis: { [key in ContractType]: ContractInterface },
        private default_chain_id: number,
    ) {
        for (const chain_id of Object.keys(rpcManagers)) {
            this.managers[chain_id] = new SinlgeChainContractManager(
                rpcManagers[chain_id],
            );
        }
    }

    public async init(): Promise<void> {
        await Promise.all(
            Object.values(this.managers).map((manager) => manager.init()),
        );
    }

    public getContract(type: ContractType, chainId?: number): ContractWrapper {
        if (!chainId) chainId = this.default_chain_id;
        return this.managers[chainId].getContract(
            this.getContractAddress(type, chainId),
            this.abis[type],
        );
    }

    public getContractByAddress(
        address: string,
        type: ContractType,
        chainId?: number,
    ): ContractWrapper {
        if (!chainId) chainId = this.default_chain_id;

        return this.managers[chainId].getContract(address, this.abis[type]);
    }

    public getContractByAddressAndABI(
        address: string,
        abi: ContractInterface,
        chainId?: number,
    ): ContractWrapper {
        if (!chainId) chainId = this.default_chain_id;

        return this.managers[chainId].getContract(address, abi);
    }

    public getContractAddress(type: ContractType, chainId?: number): string {
        if (!chainId) chainId = this.default_chain_id;

        if (
            [
                ContractType.MultiCall,
                ContractType.Investments,
                ContractType.EventFactory,
                ContractType.LegacyEventFactory,
                ContractType.CompoundStaking,
                ContractType.Vault,
                ContractType.Tiers,
                ContractType.LiquidityStaking,
            ].includes(type)
        ) {
            return this.config[type][chainId];
        }

        if (
            [ContractType.BaseToken, ContractType.PaymentToken].includes(type)
        ) {
            return this.config[type][chainId].address;
        }

        return this.config[type];
    }

    public getContractInterface(type: ContractType): Interface {
        return new Interface(JSON.stringify(this.abis[type]));
    }

    public getProvider(chainId: number): JsonRpcProvider {
        return this.rpcManagers[chainId].provider;
    }

    public async multicall(
        calls: Call[],
        params: MultiCallParams = {},
    ): Promise<Result[]> {
        let { chain_id } = params;
        if (!chain_id) chain_id = this.default_chain_id;
        const { default_iface } = params;

        const multicall = this.getContract(ContractType.MultiCall, chain_id);

        const mapped_calls = calls.map(
            ({ iface = default_iface, target, func_name, params = [] }) => ({
                target,
                callData: iface.encodeFunctionData(func_name, params),
                params: params,
                func_name_utf8: func_name,
            }),
        );

        try {
            const res = await multicall.contract.callStatic.aggregate(
                mapped_calls,
            );
            return calls.map(({ iface = default_iface, func_name }, idx) =>
                iface.decodeFunctionResult(func_name, res.returnData[idx]),
            );
        } catch (error) {
            console.log(
                '[CONTRACT_MANAGER] Error(s) in multicall for',
                this.getProvider(chain_id).connection.url,
            );
            const converted_calls = mapped_calls.map((call) => ({
                target: call.target,
                func_name: call.callData,
                params: call.params,
                func_name_utf8: call.func_name_utf8,
            }));

            this.rpcManagers[chain_id].provider.decodeMulticallData(
                converted_calls,
            );
            return error;
        }
    }
}

export interface Call {
    target: string;
    func_name: string;
    iface?: Interface;
    params?: unknown[];
}

export interface MultiCallParams {
    default_iface?: Interface;
    chain_id?: number;
}
