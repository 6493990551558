import React from 'react';
import { LearningSection as LearningSectionUI } from '@decub8/ui';

import { IContentSection } from '@src/ts/interfaces';

export const LearningSection: React.FC<{
    section: IContentSection;
    className?: string;
}> = ({ section, className }) => {
    const has_button =
        section?.buttons && section.buttons[0]
            ? section.buttons[0].text || section.buttons[0].url
            : false;

    const button =
        section.buttons?.length === 1
            ? section?.buttons[0]
            : { url: '', text: '' };

    const learning_section_props = {
        title: section?.title,
        cta_button: has_button
            ? {
                  handleClick: () =>
                      window.open(button.url, '_blank', 'norefferer'),
                  text: button.text,
              }
            : undefined,
        data: section?.images?.map(({ name, url, link }) => ({
            image: url,
            text: name,
            link,
        })),
    };
    return (
        <LearningSectionUI {...learning_section_props} className={className} />
    );
};
