import { useEffect, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';

import { ContractType } from '@src/ts/constants';
import { Reserves } from '@src/ts/interfaces';

import { useGlobalContext } from '../useGlobalContext';

export const usePairReserves = (
    address: string,
    chain_id: number,
): Reserves => {
    const { contract_manager } = useGlobalContext();

    const [reserves, setReserves] = useState({
        reserve0: BigNumber.from(0),
        reserve1: BigNumber.from(0),
    });
    const [initial, setInitial] = useState(true);

    useEffect(() => {
        if (address && contract_manager) {
            const handleGetReserves = () =>
                contract_manager
                    .getContractByAddress(
                        address,
                        ContractType.UniswapV2Pair,
                        chain_id,
                    )
                    .contract.getReserves()
                    .then((r) => setReserves(r));

            if (initial) {
                handleGetReserves();
                setInitial(false);
            } else {
                const handler = setTimeout(handleGetReserves, 5000);
                return () => clearTimeout(handler);
            }
        }
    }, [reserves, address, chain_id, initial, contract_manager]);

    return reserves;
};
