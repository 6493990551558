import { api_client } from '@src/bootstrap/index';
import { CONFIG } from '@src/services/confg';
import { TIER } from '@src/services/tier';
import { ConfigKey } from '@src/ts/constants';
import { BackendConfigItem, ITier, IUserTier } from '@src/ts/interfaces';

export const getTierOfUser = async (): Promise<IUserTier> => {
    try {
        const { retrieveUserTier } = await api_client.query<{
            retrieveUserTier: IUserTier;
        }>({
            query: TIER.RETRIEVE_USER_TIER,
            fetchPolicy: 'network-only',
        });

        return retrieveUserTier;
    } catch (e) {
        // If error is thrown likely due to user not having wallet attached to their account yet
        console.log('Error while fetching user tier', e);
    }
};

export const getTiers = async (): Promise<ITier[]> => {
    const res = api_client.query({
        query: TIER.RETRIEVE_TIERS,
    });

    return res.then((data: { retrieveTiers: ITier[] }) => {
        return data.retrieveTiers || [];
    });
};

export const getMinLimitForTiers = async (tiers: ITier[]): Promise<number> => {
    const min_limit = tiers[0].min_limit;

    if (min_limit !== 0) return min_limit;

    const res = await api_client.query<{ retrieveConfig: BackendConfigItem }>({
        query: CONFIG.GET_CONFIG_ITEM,
        variables: { key: ConfigKey.MinTokensForTierNoMin },
    });

    return parseFloat(res.retrieveConfig.value);
};

export const getCorrectTier = (tier_idx: number, tiers: ITier[]): ITier => {
    try {
        return tiers.find((tier) => tier.id === tier_idx);
    } catch (e) {
        return null;
    }
};

export const weightToTier = (weight: number, tiers: ITier[]): ITier => {
    for (let i = 0; i < tiers.length; i++) {
        if (weight < tiers[i].min_limit) {
            return tiers[i];
        }
    }
    return tiers[tiers.length - 1];
};
