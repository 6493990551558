export const steps = [
    {
        name: 'create_account',
        title: 'Create account',
    },
    {
        name: 'verify_id',
        title: 'Verify your ID',
    },
    {
        name: 'verify_wallet',
        title: 'Verify your wallet',
    },
    {
        name: 'invest',
        title: 'Invest',
    },
];
