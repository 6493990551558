import React from 'react';
import Link from 'next/link';

import { CONTENT } from '@src/config';
import { useCountdown } from '@src/hooks';
import { useWalletInfo } from '@src/hooks/useWalletInfo';
import { User } from '@src/ts/interfaces';
import { displayCountdown } from '@src/utils/format';
import { shortenHex } from '@src/utils/web3';

import AccountItem from './AccountItem';

const getText = (user: User, is_verified: boolean): string => {
    if (user && !user.wallet_address) {
        return 'No verified wallet';
    }
    if (user?.wallet_address && !is_verified) {
        return 'Wallet verification pending...';
    }

    return shortenHex(user?.wallet_address || '', 6);
};

const Timer: React.FC<{ cooldown: string }> = ({ cooldown }) => {
    const time_left = useCountdown(cooldown);

    return (
        <p className="text-white">
            <strong>
                {time_left &&
                    !isNaN(time_left.seconds()) &&
                    displayCountdown(time_left)}
            </strong>
        </p>
    );
};

export const WalletInfo: React.FC<{ user: User }> = ({ user }) => {
    const {
        account,
        connect,
        is_whitelisted,
        cooldown,
        connected_wallet_is_selected,
    } = useWalletInfo(user);
    const { wallet_address } = user || {};

    return (
        <div>
            <h4 className="text-lg my-8">
                <strong>Verified wallet</strong>
            </h4>

            {(!wallet_address || !connected_wallet_is_selected || cooldown) && (
                <div className="mb-6 border border-secondary rounded text-secondary p-4">
                    {!wallet_address &&
                        `You need to verify your wallet to use all ${CONTENT.companyName} products without restrictions.`}
                    {!connected_wallet_is_selected &&
                        `Connect your verified wallet to use all ${CONTENT.companyName} products without restrictions.`}
                    {connected_wallet_is_selected && cooldown && (
                        <div className="flex">
                            <div className="flex-1">
                                You can change your verified wallet again in
                            </div>
                            <Timer cooldown={cooldown} />
                        </div>
                    )}
                </div>
            )}

            <AccountItem title="Verified wallet">
                <div className="flex items-center">
                    <h5 className="text-lg flex-1">
                        <div className="md:flex items-center">
                            <p>{getText(user, is_whitelisted)}</p>

                            {wallet_address && (
                                <div className="md:ml-4 px-2 py-1 w-28 text-center my-1 md:my-0 md:w-auto rounded-full bg-surface-level-two border border-border border-opacity-5 text-secondary text-sm">
                                    {connected_wallet_is_selected
                                        ? 'Connected'
                                        : 'Not connected'}
                                </div>
                            )}
                        </div>
                    </h5>
                    {(!wallet_address && (
                        <Link
                            className="text-accent underline"
                            href="/verify/wallet"
                        >
                            Verify wallet
                        </Link>
                    )) ||
                        (!account && (
                            <button
                                className="text-accent underline"
                                onClick={() => connect()}
                            >
                                Connect
                            </button>
                        )) ||
                        (!cooldown && connected_wallet_is_selected && (
                            <Link
                                href="/change/wallet"
                                className="text-accent underline"
                            >
                                Change
                            </Link>
                        ))}
                </div>
            </AccountItem>
        </div>
    );
};
