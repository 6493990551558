import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import duration, { Duration } from 'dayjs/plugin/duration';

dayjs.extend(duration);

// pass in iso string format: '2022-01-01T00:00:00.000Z'
export const useCountdown = (time: string): Duration => {
    const [time_left, setTimeLeft] = useState<Duration>(dayjs.duration(0));

    useEffect(() => {
        const calculateTimeLeft = () => {
            const diff = Math.max(dayjs(time).diff(dayjs()), 0);
            setTimeLeft(dayjs.duration(diff));
        };

        calculateTimeLeft();

        const handler = setInterval(calculateTimeLeft, 1000);

        return () => {
            clearInterval(handler);
        };
    }, [time]);

    // Return a default value until the countdown is calculated
    return time_left || dayjs.duration(0);
};
