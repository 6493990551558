import React, { useEffect, useState } from 'react';
import { Typography } from '@decub8/ui';
import { commify } from '@ethersproject/units';

import { useTokenMetrics } from '@src/hooks/useTokenMetrics';

const TokenMetrics = () => {
    const [slug, setSlug] = useState('');

    useEffect(() => {
        const current_slug = window.location.href.split('/')[4];
        setSlug(current_slug);
    }, []);

    const { token_sale_data, token_allocation_data } = useTokenMetrics(slug);

    if (token_sale_data?.length === 0 && token_allocation_data?.length === 0)
        return (
            <Typography variant="secondary" className="text-center">
                More details coming soon!
            </Typography>
        );

    return (
        <div className="max-w-[800px] mx-auto">
            {/* Token Sale Section */}
            <div className="my-5">
                <Typography allBold className="mt-[60px]" size="3xl">
                    Token sale
                </Typography>
                <div className="flex h-px bg-surface-level-two my-5"></div>

                {token_sale_data?.length > 0 &&
                    token_sale_data.map(({ label, value }, index) => (
                        <div
                            key={index}
                            className={`max-w-[780px] h-[60px] grid grid-cols-2 items-center px-5 gap-4 py-2 ${
                                index % 2 === 0
                                    ? ''
                                    : 'bg-gradient-to-r from-surface-level-one rounded-lg to-transparent'
                            }`}
                        >
                            <Typography variant="secondary">{label}</Typography>
                            <Typography size="2xl" allBold>
                                {value}
                            </Typography>
                        </div>
                    ))}
            </div>

            {/* Token Allocation Section */}
            {token_allocation_data?.length > 0 && (
                <div className="my-5">
                    <Typography allBold className="mt-[60px]" size="3xl">
                        Token allocation
                    </Typography>
                    <div className="flex h-px bg-surface-level-two my-5"></div>

                    {token_allocation_data.map(
                        ({ label, value, percentage }, index: number) => (
                            <div
                                key={index}
                                className={`max-w-[780px] h-[60px] grid grid-cols-3 items-center px-8 sm:px-5 gap-8 ${
                                    index % 2 === 0
                                        ? ''
                                        : 'bg-gradient-to-r from-surface-level-one rounded-lg to-transparent'
                                }`}
                            >
                                <Typography variant="secondary" size="md">
                                    {label}
                                </Typography>
                                <Typography size="2xl" allBold>
                                    ${isNaN(value) ? value : commify(value)}
                                </Typography>
                                <Typography
                                    size="2xl"
                                    allBold
                                    className="col-start-3 justify-self-end sm:col-start-auto sm:justify-self-auto"
                                >
                                    {percentage !== 'N/A'
                                        ? `${percentage}%`
                                        : 'N/A'}
                                </Typography>
                            </div>
                        ),
                    )}
                </div>
            )}
        </div>
    );
};

export default TokenMetrics;
