import { useContext, useState } from 'react';
import { Button, ModalProps, RefundModalProps } from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';
import dayjs from 'dayjs';

import { useTimedText } from '@src/components/Project/Event/TimeText';
import { CONTENT, CONTRACT, DEFAULT_CHAIN_ID } from '@src/config';
import { ITier } from '@src/ts/interfaces';
import { getRefundFee } from '@src/utils/user';
import { parseBalance } from '@src/utils/web3';

import { GlobalContext } from '../useGlobalContext';
import { useInvestmentContext } from '../usePortfolioContext';

export const useRefundModal = (event_name): [RefundModalProps, ModalProps] => {
    const [loading, setLoading] = useState(false);

    const { _userTier, _tiers, _setTierDrawerOpen } = useContext(GlobalContext);
    const {
        refund_details,
        refund_modal_open,
        refund_complete_modal_open,
        setRefundModalOpen,
        setRefundCompleteModalOpen,
    } = useInvestmentContext();
    const current_tier =
        (_userTier?.id >= 0 && _tiers[_userTier?.id]) || ({} as ITier);

    const payment_token =
        CONTRACT.PaymentToken[
            refund_details?.refund_chain_id || DEFAULT_CHAIN_ID
        ];

    const refund_fee = getRefundFee(
        current_tier.refund_fee,
        _userTier?.multiplier || 1,
    );
    const refund_fee_amount = BigNumber.from(
        refund_details?.total_invested || '0',
    )
        .mul(Math.floor(refund_fee * 100) || 0)
        .div(10_000);
    const refund_amount = BigNumber.from(
        refund_details?.total_invested || '0',
    ).sub(refund_fee_amount);

    const onClose = () => setRefundModalOpen(false);
    const handleContinueClick = async () => {
        setLoading(true);
        const complete = await refund_details?.handleRefund();
        setLoading(false);
        setRefundModalOpen(false);
        if (complete) setRefundCompleteModalOpen(true);
    };
    const onUpgradeClick = () => {
        setRefundModalOpen(false);
        _setTierDrawerOpen(true);
    };

    const completeOnClose = () => setRefundCompleteModalOpen(false);

    const has_required_tier = refund_fee < 100;

    const time = useTimedText(
        dayjs(refund_details?.refund_deadline),
        false,
        true,
        true,
    );

    return [
        {
            handleContinueClick: has_required_tier
                ? handleContinueClick
                : onUpgradeClick,
            handleCancelClick: onClose,
            loading,
            handleInfoClick: () =>
                refund_fee !== 0 && has_required_tier
                    ? onUpgradeClick()
                    : window.open(CONTENT.refundPolicy, '_blank', 'norefferer'),
            info_text:
                refund_fee !== 0 && has_required_tier
                    ? 'Higher tier, lower refund fee.'
                    : 'It is simple and keeps your investments safe.',
            label_text:
                refund_fee !== 0 && has_required_tier
                    ? 'Reduce refund fee'
                    : 'Want to know more about our refund policy?',
            info_button_text:
                refund_fee !== 0 && has_required_tier
                    ? 'Upgrade tier'
                    : 'Learn more',
            continue_btn_text: has_required_tier
                ? 'Refund'
                : 'Upgrade to refund',
            refund_data_arr: [
                {
                    label: 'Invested',
                    values: [
                        {
                            value: `${parseBalance(
                                refund_details?.total_invested || '0',
                                payment_token.decimals,
                            )} ${payment_token.symbol}`,
                        },
                    ],
                },
                {
                    label: 'Refund fee',
                    values: [
                        {
                            value: `${parseBalance(
                                refund_fee_amount,
                                payment_token.decimals,
                            )} ${payment_token.symbol} (${refund_fee?.toFixed(
                                1,
                            )}%)`,
                        },
                    ],
                    tooltip:
                        'Refund fee is deducted from the invested amount. Upgrade your investor tier to reduce the refund fee.',
                },
                {
                    label: 'Refund total',
                    values: [
                        {
                            value: `${parseBalance(
                                refund_amount,
                                payment_token.decimals,
                            )} ${payment_token.symbol}`,
                        },
                    ],
                },
                {
                    label: 'Tier required',
                    values: [
                        {
                            value: `${CONTENT.tiers.tierConstants[1].name} and above`,
                            color: 'text-error',
                        },
                    ],
                    tooltip: `Only ${CONTENT.tiers.tierConstants[1].name} and above tier investors can request a refund.`,
                },
                {
                    label: 'Refund deadline',
                    values: [{ value: time }],
                    tooltip:
                        'Refund for this event is available up until the specified date/time.',
                },
            ].filter((i) =>
                i.label === 'Tier required' ? !has_required_tier : true,
            ),
            refund_project_name: `Refund ${event_name}`,
            show: refund_modal_open,
            onClose,
        },
        {
            title: 'Refund complete',
            desc: 'The funds have been transferred to your wallet.',
            children: (
                <Button
                    loading={loading}
                    onClick={completeOnClose}
                    className="w-full"
                >
                    OK
                </Button>
            ),
            noClose: true,
            show: refund_complete_modal_open,
            onClose: completeOnClose,
        },
    ];
};
