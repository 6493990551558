import { useCallback, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import { useRouter } from 'next/router';

import { api_client } from '@src/bootstrap';
import { useAppDispatch } from '@src/hooks/index';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { USER } from '@src/services';
import { handleLogout, newAnalyticsEvent } from '@src/utils/user';

interface IdentityState {
    loading: boolean;
    errors: { [key: string]: string };
    received_name: boolean;
}

const getKycSessionFromLocalStorage = async () => {
    const s = localStorage.getItem('decubate_KYCSession');

    if (s) return s;

    const { generateIdentityCheckSession: session } = (await api_client.mutate({
        mutation: USER.GENERATE_IDENTITY_CHECK_SESSION,
    })) as { generateIdentityCheckSession: string };

    return session;
};

interface UseUpdateUserProps {
    custom_complete_route?: string;
}

export const useKYC = ({ custom_complete_route = '/' }: UseUpdateUserProps) => {
    const [state, setState] = useState<IdentityState>({
        loading: false,
        errors: {},
        received_name: false,
    });
    const router = useRouter();
    const onboarding = useMemo(
        () => router.query.onboarding === 'true',
        [router.query],
    );

    const sdkRef = useRef<unknown | undefined>();
    const { setShowKycCompleted } = useGlobalContext();
    const dispatch = useAppDispatch();

    const handleComplete = useCallback(async (): Promise<void> => {
        const default_route = onboarding ? '/' : '/account';
        const route = custom_complete_route
            ? custom_complete_route
            : default_route;

        router.push(route);

        setShowKycCompleted(true);

        if (onboarding) {
            newAnalyticsEvent('verify_identity_onboarding');
        } else {
            newAnalyticsEvent('verify_identity');
        }
    }, [setShowKycCompleted, onboarding, router, dispatch]);

    const loadAndStartKyc = useCallback(async (): Promise<void> => {
        try {
            const session = await getKycSessionFromLocalStorage();

            setState((s) => ({ ...s, loading: true, received_name: true }));

            sdkRef.current = createVeriffFrame({
                url: session,
                onEvent: function (msg) {
                    switch (msg) {
                        case MESSAGES.FINISHED:
                            localStorage.setItem('decubate_KYCSession', '');
                            handleComplete();
                            break;
                        case MESSAGES.CANCELED:
                            localStorage.setItem('decubate_KYCSession', '');
                            router.push(onboarding ? '/' : '/account');
                            break;
                        default:
                            break;
                    }
                }.bind(this),
                onReload: () => {
                    localStorage.setItem('decubate_KYCSession', session);
                    window.location.reload();
                },
            });

            setState((s) => ({ ...s, loading: false }));
        } catch (err) {
            if (err.message === 'unauthorized') {
                handleLogout(router);
            } else {
                toast.error(err.message);
            }
            setState({ ...state, loading: false });
        }
    }, [router, onboarding, handleComplete]);

    return {
        state,
        loadAndStartKyc,
        onboarding,
    };
};
