import React from 'react';
import {
    Alert,
    Button,
    DataLines,
    Media,
    MediaVariant,
    Typography,
} from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';
import { commify, formatUnits } from '@ethersproject/units';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';

import { getFormattedTimeLeft } from '@src/components/HomepageComponents/MainEventCardContainer/utils';
import { CONTENT, CONTRACT, DEFAULT_CHAIN_ID } from '@src/config';
import { useAppSelector, useCountdown } from '@src/hooks';

import { useProjectContext } from '../context';

export const CrowdfundingComplete: React.FC = () => {
    const router = useRouter();
    const { user } = useAppSelector((state) => state?.auth) || {};

    const {
        event,
        crowdfunding: { user_investment } = {
            user_investment: '0',
        },
    } = useProjectContext();

    const {
        event_details: { vesting },
        refund_deadline,
    } = event || { event_details: {} };

    const event_chain_id = event?.chainId || DEFAULT_CHAIN_ID;

    const { decimals: payment_decimals, symbol: payment_symbol } =
        CONTRACT.PaymentToken[event_chain_id];

    const vesting_start = vesting?.start
        ? dayjs(Number(vesting.start) * 1000)
        : undefined;

    const vesting_started = dayjs().isAfter(vesting_start);

    const time_left_to_vesting_start = getFormattedTimeLeft(
        useCountdown(vesting_start?.toISOString()),
    );

    const invested = BigNumber.from(user_investment).gt(0);

    const handleClick = async () =>
        router.push(`/portfolio/${user?.wallet_address}`);

    // TODO: test what it looks like when claimin tokens

    return (
        <div>
            {invested && user && (
                <>
                    <div className="space-y-[10px]">
                        <Typography variant="secondary">{`Thank you for investing in ${event.name}, you can claim the token after the token generation event. `}</Typography>
                        <DataLines
                            size="md"
                            label="Total invested"
                            values={[
                                {
                                    value: `${commify(
                                        formatUnits(
                                            user_investment,
                                            payment_decimals,
                                        ),
                                    )} ${payment_symbol}`,
                                },
                            ]}
                        />
                        <div className="flex justify-between">
                            <Typography size="md" variant="secondary">
                                Claim tokens
                            </Typography>
                            <Typography
                                variant="custom"
                                size="md"
                                className={
                                    vesting_started
                                        ? 'text-success'
                                        : 'text-primary'
                                }
                            >
                                {vesting_started
                                    ? 'Available now'
                                    : time_left_to_vesting_start}
                            </Typography>
                        </div>
                        <DataLines
                            size="md"
                            label="Refund duration"
                            values={[
                                {
                                    value: `${refund_deadline / 86400} ${
                                        refund_deadline > 0 ? 'days' : 'day'
                                    }`,
                                },
                            ]}
                        />
                    </div>
                    <Button className="w-full mt-5" onClick={handleClick}>
                        {vesting_started
                            ? 'Claim in My portfolio'
                            : 'View in My portfolio'}
                    </Button>
                </>
            )}
            {user && !invested && (
                <Alert variant="warning">
                    <span className="text-warning">
                        You did not participate in this event.
                    </span>
                </Alert>
            )}
            {!user && (
                <>
                    {' '}
                    <Typography variant="secondary">
                        {' '}
                        To stay informed about future opportunities, join our
                        community to receive updates on upcoming events and
                        whitelist openings.
                    </Typography>{' '}
                    <span className="flex space-x-10 my-4">
                        {CONTENT.social.slice(0, 3).map(({ type, url }) => (
                            <a
                                href={url}
                                key={type + '-' + url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Media
                                    variant={type as MediaVariant}
                                    size={0}
                                    className={`text-accent hover:text-white h-[18px]`}
                                />
                            </a>
                        ))}
                    </span>
                </>
            )}
        </div>
    );
};
